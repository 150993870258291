var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{attrs:{"id":"page-topbar"}},[_c('div',{staticClass:"navbar-header"},[_vm._m(0),_c('div',{staticClass:"d-flex"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"dropdown d-inline-block user-dropdown"},[_c('button',{staticClass:"btn header-item waves-effect",attrs:{"type":"button","id":"page-header-user-dropdown","data-bs-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('img',{staticClass:"rounded-circle header-profile-user",attrs:{"src":"assets/images/default-user.png","alt":"Header Avatar"}}),_c('span',{staticClass:"d-none d-xl-inline-block ms-1"},[_vm._v(_vm._s(_vm.users.name))]),_c('i',{staticClass:"mdi mdi-chevron-down d-none d-xl-inline-block"})]),_c('div',{staticClass:"dropdown-menu dropdown-menu-end"},[_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"dropdown-divider"}),_c('router-link',{staticClass:"dropdown-item text-danger",attrs:{"to":{name: 'signout'}}},[_c('i',{staticClass:"ri-shut-down-line align-middle me-1 text-danger"}),_vm._v(" Logout")])],1)])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"modal-manual-book","tabindex":"-1","role":"dialog","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('iframe',{staticStyle:{"min-height":"600px"},attrs:{"src":_vm.generateUrlManualBook}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"navbar-brand-box text-center"},[_c('a',{staticClass:"logo logo-dark",attrs:{"href":"index.html"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":"assets/images/favicon.png","alt":"logo-dark","height":"24"}}),_c('img',{staticClass:"ms-2",attrs:{"src":"assets/images/logo-name.png","alt":"logo-dark","height":"24"}})])]),_c('a',{staticClass:"logo logo-light",attrs:{"href":"index.html"}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":"assets/images/logo-sm.png","alt":"logo-sm-light","height":"22"}})]),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":"assets/images/logo-light.png","alt":"logo-light","height":"24"}})])])]),_c('button',{staticClass:"btn btn-sm px-3 font-size-24 header-item waves-effect",attrs:{"type":"button","id":"vertical-menu-btn"}},[_c('i',{staticClass:"ri-menu-2-line align-middle"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown d-inline-block d-lg-none ms-2"},[_c('button',{staticClass:"btn header-item noti-icon waves-effect",attrs:{"type":"button","id":"page-header-search-dropdown","data-bs-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"ri-search-line"})]),_c('div',{staticClass:"dropdown-menu dropdown-menu-lg dropdown-menu-end p-0",attrs:{"aria-labelledby":"page-header-search-dropdown"}},[_c('form',{staticClass:"p-3"},[_c('div',{staticClass:"mb-3 m-0"},[_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Search ..."}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_c('i',{staticClass:"ri-search-line"})])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown d-inline-block d-none"},[_c('button',{staticClass:"btn header-item noti-icon waves-effect",attrs:{"type":"button","id":"page-header-notifications-dropdown","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"ri-notification-3-line"}),_c('span',{staticClass:"noti-dot"})]),_c('div',{staticClass:"dropdown-menu dropdown-menu-lg dropdown-menu-end p-0",attrs:{"aria-labelledby":"page-header-notifications-dropdown"}},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"m-0"},[_vm._v(" Notifications ")])]),_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"small",attrs:{"href":"#!"}},[_vm._v(" View All")])])])]),_c('div',{staticStyle:{"max-height":"230px"},attrs:{"data-simplebar":""}},[_c('a',{staticClass:"text-reset notification-item",attrs:{"href":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-shrink-0 me-3"},[_c('div',{staticClass:"avatar-xs"},[_c('span',{staticClass:"avatar-title bg-primary rounded-circle font-size-16"},[_c('i',{staticClass:"ri-shopping-cart-line"})])])]),_c('div',{staticClass:"flex-grow-1"},[_c('h6',{staticClass:"mb-1"},[_vm._v("Your order is placed")]),_c('div',{staticClass:"font-size-12 text-muted"},[_c('p',{staticClass:"mb-1"},[_vm._v("If several languages coalesce the grammar")]),_c('p',{staticClass:"mb-0"},[_c('i',{staticClass:"mdi mdi-clock-outline"}),_vm._v(" 3 min ago")])])])])])]),_c('div',{staticClass:"p-2 border-top"},[_c('div',{staticClass:"d-grid"},[_c('a',{staticClass:"btn btn-sm btn-link font-size-14 text-center",attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"mdi mdi-arrow-right-circle me-1"}),_vm._v(" View More.. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"ri-user-line align-middle me-1"}),_vm._v(" Profile")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"data-bs-toggle":"modal","data-bs-target":"#modal-manual-book","href":"javascript:void(0)"}},[_c('i',{staticClass:"ri-book-line align-middle me-1"}),_vm._v(" Buku Panduan")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"ri-lock-unlock-line align-middle me-1"}),_vm._v(" Change Password")])
}]

export { render, staticRenderFns }