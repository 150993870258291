<template>
<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Kelola Data Guru</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <router-link :to="{name: 'dashboard'}">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item active">Kelola Guru</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <div class="card-title mb-4 text-muted">Filter</div>
                            <div>
                                <router-link :to="{name: 'add_teacher'}" class="btn btn-primary"><i class="mdi mdi-plus me-2"></i>Tambah Data Baru</router-link>
                                <button type="button" class="btn ms-2" @click="deleteBatch()" :class="{'btn-secondary': selectedId.length == 0, 'btn-danger': selectedId.length > 0}" :disabled="selectedId.length == 0"><i class="mdi mdi-delete me-2"></i>Hapus Terpilih</button>
                                <button type="button" class="btn ms-2 btn-white" @click="exportExcel()"><i class="mdi mdi-download me-2"></i>Export</button>
                                <button type="button" class="btn ms-2 btn-white" data-bs-toggle="modal" data-bs-target="#modal-import"><i class="mdi mdi-upload me-2"></i>Import</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 mb-2">
                                <label>Jurusan</label>
                                <select class="form-select border-muted" v-model="params.program_study_id" @change="getData()">
                                    <option value="">Semua Jurusan</option>
                                    <option v-for="row in list_program_study" :value="row.id">{{row.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-2">
                                <label>Jenjang</label>
                                <select class="form-select border-muted" v-model="params.education_id" @change="getData()">
                                    <option value="">Semua Jenjang</option>
                                    <option v-for="row in list_education" :value="row.id">{{row.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-2">
                                <label>Status</label>
                                <select class="form-select border-muted" v-model="params.status" @change="getData()">
                                    <option value="">Semua Status</option>
                                    <option value="tetap">Tetap</option>
                                    <option value="tidak tetap">Tidak Tetap</option>
                                </select>
                            </div>
                            <div class="col-md-5 mb-2">
                                <label>Kata Kunci</label>
                                <input type="text" class="form-control border-muted" placeholder="Cari guru berdasarkan nip atau nama disini ..." v-model="searchQuery">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4 text-muted">Dibawah ini terdapat {{filterItems(dataList).length}} guru yang terdaftar didalam sistem.</h4>
                        <div class="table-responsive" v-if="filterItems(dataList).length > 0">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" style="transform: scale(1.5);" id="customCheckall" v-model="checkAll" @change="selectAllData()">
                                                <label class="form-check-label" for="customCheckall"></label>
                                            </div>
                                        </th>
                                        <th colspan=2>NIP & Nama</th>
                                        <th>Jurusan</th>
                                        <th>Jenjang</th>
                                        <th>Status</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in filterItems(dataList)" :key="row.id">
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck1" style="transform: scale(1.5);" @change="selectData(row.id)" :checked="selectedId.indexOf(row.id) != -1">
                                                <label class="form-check-label" for="customCheck1"></label>
                                            </div>
                                        </td>
                                        <td style="width: 60px;">
                                            <img :src="row.picture ? row.picture : 'assets/images/default-user.png'" alt="user" class="avatar-xs rounded-circle" />
                                        </td>
                                        <td>
                                            <p class="mb-1 font-size-12">#{{row.identity_number}}</p>
                                            <h5 class="font-size-15 mb-0">{{row.name}}</h5>
                                        </td>
                                        <td>{{row.program_study}}</td>
                                        <td>{{row.education}}</td>
                                        <td>
                                            <div v-if="row.status == 'tetap'">
                                                <i class="mdi mdi-checkbox-blank-circle text-success me-1"></i> Tetap
                                            </div>
                                            <div v-if="row.status == 'tidak tetap'">
                                                <i class="mdi mdi-checkbox-blank-circle text-warning me-1"></i> Tidak Tetap
                                            </div>
                                        </td>
                                        <td width="15%">
                                            <router-link :to="{name: 'edit_teacher', params: {id: row.id}}" class="btn btn-outline-success btn-sm me-2">Edit</router-link>
                                            <button type="button" class="btn btn-outline-danger btn-sm me-2" @click="deleteData(row)">Hapus</button>
                                            <button type="button" class="btn btn-outline-warning btn-sm" @click="detailStudent(row)" data-bs-toggle="modal" data-bs-target="#modal-change-password">Ubah Password</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="bg-light p-3 rounded text-center text-muted" v-if="filterItems(dataList).length == 0">
                            Data tidak ditemukan
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

    </div>
    <!-- container-fluid -->
    <div id="modal-change-password" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-block">
                        <h5 class="modal-title" id="myModalLabel">{{form.name}}</h5>
                        <h6 class="text-muted" id="myModalLabel">{{form.identity_number}}</h6>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="password">Password Baru</label>
                        <input :type="!showPassword ? 'password' : 'text'" class="form-control" id="password" name="password" autocomplete="off" :class="{ 'is-invalid': submitted && $v.form.password.$error }" v-model="form.password" placeholder="Enter new password ...">
                        <input type="checkbox" class="mt-2 mb-3" id="show-password" style="scale: 1.2" v-model="showPassword"> <label for="show-password">Lihat Password</label>
                        <div v-if="submitted && !$v.form.password.required" class="invalid-feedback">Password is required!</div>
                        <div class="alert alert-info mt-2"><i class="mdi mdi-information me-2"></i>Use a minimum of 8 or more characters with a combination of letters (A-Z) and numbers (0-9)</div>
                    </div>
                    <div class="form-group">
                        <label for="confirm_password">Konfirmasi Password</label>
                        <input :type="!showPasswordConfirm ? 'password' : 'text'" class="form-control" id="confirm_password" name="confirm_password" :class="{ 'is-invalid': submitted && $v.form.confirm_password.$error }" v-model="form.confirm_password" placeholder="Confirm new password ...">
                        <input type="checkbox" class="mt-2 mb-3" id="show-password-confirm" style="scale: 1.2" v-model="showPasswordConfirm"> <label for="show-password-confirm">Lihat Password</label>
                        <div v-if="submitted && !$v.form.confirm_password.required" class="invalid-feedback">Confirm Password is required!</div>
                        <div class="alert alert-info mt-2"><i class="mdi mdi-information me-2"></i>Use a minimum of 8 or more characters with a combination of letters (A-Z) and numbers (0-9)</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" ref="closeModal">Tutup</button>
                    <button type="button" class="btn btn-primary waves-effect waves-light" @click="changePassword()">Ubah Password</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div>
    <div id="modal-import" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-block">
                        <h5 class="modal-title" id="myModalLabel">Import Siswa</h5>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="password">Lampiran File</label>
                        <input type="file" class="form-control" @change="previewFiles" ref="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    </div>
                    <div class="mt-3 mb-2">Silahkan download format file excel dibawah ini.</div>
                    <a href="javascript:void(0)" @click="downloadFormat()">Download Format</a>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" ref="closeModalImport">Tutup</button>
                    <button type="button" class="btn btn-primary waves-effect waves-light" @click="importData()">Import</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div>
</div>
</template>
<script>
export default {
    name: 'Teacher',
    metaInfo: {
        title: "Guru",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            selectedId: [],
            checkAll: false,
            dataFetch: false,
            showPassword: false,
            showPasswordConfirm: false,
            searchQuery: '',
            submitted: false,
            submittedUpload: false,
            dataList: [],
            list_program_study: [],
            list_education: [],
            params: {
                program_study_id: '',
                education_id: '',
                status: ''
            },
            form: {
                npm: '',
                name: '',
                password: '',
                confirm_password: ''
            },
            export: {
                file: null,
            }
        }
    },
    component: {},
    created() {
        this.getData();
        this.getStudyProgram();
        this.getEducation();
    },
    methods: {
        selectAllData: function () {
            if (this.checkAll) {
                this.dataList.forEach(element => {
                    this.selectData(element.id)
                });
            } else {
                this.selectedId = []
            }
        },
        selectData: function (id) {
            if (this.selectedId.indexOf(id) != -1)
                this.selectedId.splice(this.selectedId.indexOf(id), 1)
            else
                this.selectedId.push(id)
        },
        getData: function () {
            this.dataList = []
            let uri = process.env.VUE_APP_APIHOST + 'administrator/teacher/list';

            this.dataFetch = true;
            this.$http.get(uri, {params: this.params}).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
            });
        },
        deleteData: function (data) {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus data dengan nama ' + data.name,
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/teacher/delete', {
                            id: data.id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteBatch: function () {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus ' + this.selectedId.length + ' data yang terpilih',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/teacher/delete-batch', {
                            list_id: this.selectedId,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.selectedId=[];
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            if (data) {
                return data.filter(function (result) {
                    let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                    return result.identity_number.toString().match(regex) || result.name.match(regex);
                })
            } else {
                return []
            }
        },
        getStudyProgram: function() {
            let uri     = process.env.VUE_APP_APIHOST+'program-study/list';

            this.$http.get(uri,{
            }).then(res => {
                if(res.data.status) {
                    this.list_program_study = res.data.data;
                }
            });
        },
        getEducation: function() {
            let uri     = process.env.VUE_APP_APIHOST+'education/list';

            this.$http.get(uri,{
            }).then(res => {
                if(res.data.status) {
                    this.list_education = res.data.data;
                }
            });
        },
        detailStudent: function(detail) {
            this.form = detail
            this.form.role_id = 3
        },
        changePassword: function() {
            let formData = new FormData();

            for (var index in this.form) {
                formData.append(index, this.form[index]);
            }

            this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/student/change-password', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        });
                        this.$refs.closeModal.click();
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
        },
        exportExcel: function() {
            let uri     = process.env.VUE_APP_APIHOST+'administrator/teacher/export/excel';

            this.$http.get(uri,{
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                if(response.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Daftar Guru.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            });
        },
        importData: function() {
            this.submittedUpload = true;
            let formData = new FormData();
            formData.append('file', this.export.file);

            this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/teacher/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.getData()
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.$refs.closeModalImport.click()
                    this.submittedUpload = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.submittedUpload = false;
                    return;
                });
        },
        downloadFormat: function() {
            let uri     = process.env.VUE_APP_APIHOST+'administrator/teacher/format/excel';

            this.$http.get(uri,{
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                if(response.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Format Import Guru.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            });
        },
        previewFiles(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.export.file = file;
            }
        },
    },
}
</script>
