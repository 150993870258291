<template>
<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row justify-content-center">
            <div class="col-8">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Kelola Kelas Online</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'courses' }">Kelas Online</router-link>
                            </li>
                            <li class="breadcrumb-item active">Form Kelas Online</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex text-muted">
                            <div class="flex-shrink-0 me-3 align-self-center">
                                <div id="radialchart-1" class="apex-charts" dir="ltr"></div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <p class="mb-1">Silahkan lengkapi form dibawah ini</p>
                                <form class="mt-3" v-on:submit.prevent="onSubmit">
                                    <div class="form-group mb-3">
                                        <label class="form-label" for="code">Kode</label>
                                        <input class="form-control" type="text" id="name" v-model="form.code" placeholder="Masukan kode kelas">
                                    </div>

                                    <div class="form-group mb-3">
                                        <label class="form-label" for="name">Nama</label>
                                        <input class="form-control" :class="{ 'is-invalid': submitted && $v.form.name.$error }" type="text" id="name" v-model="form.name" placeholder="Masukan nama mata pelajaran">
                                        <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required!</div>
                                    </div>

                                    <div class="form-group mb-3 row">
                                        <div class="col-md-6">
                                            <label class="form-label" for="course_category_id">Mata Pelajaran</label>
                                            <select class="form-select border-muted" v-model="form.course_category_id" :class="{ 'is-invalid': submitted && $v.form.course_category_id.$error }">
                                                <option value="">Pilih Mata Pelajaran</option>
                                                <option v-for="row in categoryList" :value="row.id" :key="row.id">{{row.name}}</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.course_category_id.required" class="invalid-feedback">Mata kuliah is required!</div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label" for="periode_id">Periode</label>
                                            <select class="form-select border-muted" v-model="form.periode_id" :class="{ 'is-invalid': submitted && $v.form.periode_id.$error }">
                                                <option value="">Pilih Periode</option>
                                                <option v-for="row in periodeList" :value="row.id" :key="row.id">{{row.name}} {{row.active == '1' ? '(Aktif)' : ''}}</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.periode_id.required" class="invalid-feedback">Periode is required!</div>
                                        </div>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label class="form-label" for="teacher_id">Guru Pengajar</label>
                                        <select class="form-select border-muted" v-model="form.teacher_id">
                                            <option value="">Pilih Guru</option>
                                            <option v-for="row in teacherList" :value="row.user_id" :key="row.id">{{row.name}}</option>
                                        </select>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label for="description">Deskripsi</label>
                                        <ckeditor :config="editorConfig" v-model="form.description" class="mt-2"></ckeditor>
                                    </div>

                                    <div class="form-group mb-3 row">
                                        <div class="col-md-6">
                                            <label for="study_group_id">Rombongan Belajar</label>
                                            <multiselect v-model="form.study_group_id" :multiple="true" :options="studyGroupList" placeholder="Choose study group" :show-labels="false" label="name" track-by="name">
                                                <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                            </multiselect>
                                            <div v-if="submitted && !$v.form.study_group_id.required"
                                                class="invalid-feedback">Rombongan Belajar
                                                is required!</div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label" for="status">Status</label>
                                            <select class="form-select border-muted" v-model="form.status">
                                                <option value="publish">Publish</option>
                                                <option value="unpublish">Unpublish</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-end mt-4">
                                        <router-link :to="{name: 'courses'}" class="btn btn-light me-2"><i class="mdi mdi-arrow-left me-2"></i>Batal</router-link>
                                        <button type="submit" class="btn btn-primary"><i class="mdi mdi-content-save me-2"></i>Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

    </div>
    <!-- container-fluid -->
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";
export default {
    name: 'FormCourses',
    metaInfo: {
        title: "Form Kelas Online",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            id: this.$route.params.id,
            submitted: false,
            waiting: false,
            categoryList: [],
            teacherList: [],
            periodeList: [],
            studyGroupList: [],
            form: {
                code: '',
                name: '',
                course_category_id: '',
                periode_id: '',
                teacher_id: '',
                description: '',
                status: 'unpublish',
                cover: window.location.origin + '/assets/images/placeholder.png'
            },
            editorConfig: {
                versionCheck: false,
                toolbar: [{
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                }, {
                    name: 'paragraph',
                    items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                }, {
                    name: 'links',
                    items: ['Link', 'Unlink']
                }, {
                    name: 'insert',
                    items: ['Image', 'EmbedSemantic', 'Table']
                }, {
                    name: 'tools',
                    items: ['Maximize']
                }],
                extraPlugins: 'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                imageUploadUrl: process.env.VUE_APP_APIHOST + 'uploader/images',
                uploadUrl: process.env.VUE_APP_APIHOST + 'uploader/files',
            }
        }
    },
    validations: {
        form: {
            code: {
                required
            },
            name: {
                required
            },
            course_category_id: {
                required
            },
            periode_id: {
                required
            },
            teacher_id: {
                required
            },
        }
    },
    created() {
        this.fetchDetail();
        this.fetchCategory();
        this.fetchPeriode();
        this.fetchTeacher();
        this.fetchStudyGroup();
    },
    methods: {
        createSlug: function (text) {
            return text.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '')
        },
        generateSlugCode() {
            this.form.code = this.createSlug(this.form.name)
        },
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();
                // get list study group id
                var listStudyGroupId = [];
                for (var keyStudyGroup in this.form.study_group_id) {
                    listStudyGroupId.push(this.form.study_group_id[keyStudyGroup].id)
                }
                this.form.study_group_id = JSON.stringify(listStudyGroupId);

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/courses/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'courses'
                                    });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        fetchDetail: function() {
            if (typeof (this.id) !== 'undefined' && this.id !== null) {
                this.label  = "Update";

                let uri     = process.env.VUE_APP_APIHOST+'administrator/courses/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse = res.data.data;
                        this.form       = getResponse;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'courses' });
                            }
                        });
                    }
                });
            }
        },
        fetchCategory: function () {
            let uri = process.env.VUE_APP_APIHOST + 'category/list';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.categoryList = getResponse;
                }
            });
        },
        fetchPeriode: function () {
            let uri = process.env.VUE_APP_APIHOST + 'year/list';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.periodeList = getResponse;
                }
            });
        },
        fetchTeacher: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/list';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.teacherList = getResponse;
                }
            });
        },
        fetchStudyGroup: function () {
            let uri = process.env.VUE_APP_APIHOST + 'study-group/list';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.studyGroupList = getResponse;
                }
            });
        },
    },
}
</script>
