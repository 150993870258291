<template>
<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row justify-content-center">
            <div class="col-6">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Kelola Grade Nilai</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'grade' }">Grade Nilai</router-link>
                            </li>
                            <li class="breadcrumb-item active">Form Grade Nilai</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex text-muted">
                            <div class="flex-shrink-0 me-3 align-self-center">
                                <div id="radialchart-1" class="apex-charts" dir="ltr"></div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <p class="mb-1">Silahkan lengkapi form dibawah ini</p>
                                <form class="mt-3" v-on:submit.prevent="onSubmit">
                                    <div class="form-group mb-3 row">
                                        <div class="col-md-6">
                                            <label class="form-label" for="start_score">Skor Awal</label>
                                            <input class="form-control" :class="{ 'is-invalid': submitted && $v.form.start_score.$error }" type="number" max="100" id="start_score" v-model="form.start_score" placeholder="Masukan skor awal">
                                            <div v-if="submitted && !$v.form.start_score.required" class="invalid-feedback">Skor awal is required!</div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label" for="end_score">Skor Akhir</label>
                                            <input class="form-control" :class="{ 'is-invalid': submitted && $v.form.end_score.$error }" type="number" max="100" id="end_score" v-model="form.end_score" placeholder="Masukan skor akhir">
                                            <div v-if="submitted && !$v.form.end_score.required" class="invalid-feedback">Skor akhir is required!</div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-3 row">
                                        <div class="col-md-6">
                                            <label class="form-label" for="grade">Nilai Huruf</label>
                                            <input class="form-control" :class="{ 'is-invalid': submitted && $v.form.grade.$error }" type="text" id="grade" v-model="form.grade" placeholder="Masukan nilai huruf">
                                            <div v-if="submitted && !$v.form.grade.required" class="invalid-feedback">Nilai huruf is required!</div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label" for="status">Status</label>
                                            <select class="form-select border-muted" :class="{ 'is-invalid': submitted && $v.form.status.$error }" v-model="form.status">
                                                <option value="1">Lulus</option>
                                                <option value="0">Tidak Lulus</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.status.required" class="invalid-feedback">Status is required!</div>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-end mt-4">
                                        <router-link :to="{name: 'grade'}" class="btn btn-light me-2"><i class="mdi mdi-arrow-left me-2"></i>Batal</router-link>
                                        <button type="submit" class="btn btn-primary"><i class="mdi mdi-content-save me-2"></i>Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

    </div>
    <!-- container-fluid -->
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";
export default {
    name: 'FormGrade',
    metaInfo: {
        title: "Form Grade Nilai",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            id: this.$route.params.id,
            submitted: false,
            waiting: false,
            form: {
                start_score: '',
                end_score: '',
                ipk: '',
                grade: '',
                status: '0',
            },
        }
    },
    validations: {
        form: {
            start_score: {
                required
            },
            end_score: {
                required
            },
            grade: {
                required
            },
            status: {
                required
            },
        }
    },
    created() {
        this.fetchDetail();
    },
    methods: {
        createSlug: function (text) {
            return text.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '')
        },
        generateSlugCode() {
            this.form.code = this.createSlug(this.form.name)
        },
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/grade/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'grade'
                                    });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        fetchDetail: function() {
            if (typeof (this.id) !== 'undefined' && this.id !== null) {
                this.label  = "Update";

                let uri     = process.env.VUE_APP_APIHOST+'administrator/grade/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse = res.data.data;
                        this.form       = getResponse;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'grade' });
                            }
                        });
                    }
                });
            }
        },
    },
}
</script>
