<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4 text-muted">Menampilkan
                        {{ filterItems(dataList.list).length }} siswa.</h4>
                    <div class="table-responsive" v-if="filterItems(dataList.list).length > 0">
                        <table class="table table-centered table-bordered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th rowspan=2 class="text-center" style="width: 3%">No</th>
                                    <th rowspan=2 class="text-center" style="width: 15%">NIS</th>
                                    <th rowspan=2>Nama</th>
                                    <th :colspan="dataList.question.length" class="text-center">Nilai Hasil Belajar</th>
                                    <th rowspan=2>Benar</th>
                                    <th rowspan=2>Salah</th>
                                    <th rowspan=2>Nilai</th>
                                </tr>
                                <tr v-if="dataList.question.length > 0">
                                    <th class="text-center" style="width: 10%" v-for="col, index in dataList.question">
                                        {{ index + 1 }} ({{ col.type == 'MULTIPLE_CHOICE' ? 'PG' : 'ESSAY' }})</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in filterItems(dataList.list)" :key="row.id">
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center" style="font-weight: bold;">{{ row.identity_number }}</td>
                                    <td>{{ row.name }}</td>
                                    <td v-for="quiz in row.quiz" class="fw-bold fs-5" :class="{ 'text-center': quiz.type == 'MULTIPLE_CHOICE', 'bg-danger': quiz.status != 'correct', 'text-white': quiz.status != 'correct' }">
                                        <div v-html="quiz.answer"></div>
                                    </td>
                                    <td class="text-center fw-bold fs-5">{{ row.score.correct }}</td>
                                    <td class="text-center fw-bold fs-5">{{ row.score.wrong }}</td>
                                    <td class="text-center fw-bold fs-5">{{ row.score.score }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <template v-if="!dataFetch">
                        <div class="bg-light p-3 rounded text-center text-muted"
                            v-if="!params.courses_id || !params.quiz_id">
                            Silahkan untuk memilih <b>filter kelas, topik, dan kuis</b> untuk melihat analisis jawaban soal siswa.
                        </div>
                        <div class="bg-light p-3 rounded text-center text-muted"
                            v-if="params.courses_id && params.quiz_id && filterItems(dataList.list).length == 0">
                            Data tidak ditemukan
                        </div>
                    </template>
                </div>
                <!-- end card-body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>
<script>
export default {
    name: 'AnalysisQuizList',
    props: {
        keyword: '',
        params: {
            type: Object,
            default: {
                courses_id: '',
                topic_id: '',
                quiz_id: '',
            }
        }
    },
    metaInfo: {
        title: "Laporan Analisis Butir",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            dataFetch: false,
            dataList: {
                question: [],
                list: []
            },
        }
    },
    component: {},
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            this.dataList = {
                question: [],
                list: []
            }
            let uri = process.env.VUE_APP_APIHOST + 'teacher/report/analysis-quiz';

            this.dataFetch = true;
            this.params.type = 'answer'
            this.$http.get(uri, {
                params: this.params
            }).then(res => {
                this.dataFetch = false;
                if (res.data.status) {
                    this.dataList.question = res.data.data.question;
                    this.dataList.list = res.data.data.student;
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            if (data) {
                if (app.keyword) {
                    return data.filter(function (result) {
                        let regex = new RegExp('(' + app.keyword + ')', 'i');
                        return result.identity_number.toString().match(regex) || result.name.match(regex);
                    })
                } else {
                    return data;
                }
            } else {
                return []
            }
        },
    },
}
</script>