<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">{{detail.name}}</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard_student' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'quiz_overview', params: {courses_id: this.$route.params.courses_id, id: $route.params.id} }">Overview Kuis</router-link>
                                </li>
                                <li class="breadcrumb-item active">Kuis</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" v-if="detail.question.length > 0">
                <div class="col-md-9">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-4">
                                <div class="d-block">
                                    <div class="card-title mb-0">Pertanyaan {{detailQuestion.number}} dari {{detail.question.length}}</div>
                                    <div class="badge badge-soft-primary font-size-12" v-if="detailQuestion.type == 'MULTIPLE_CHOICE'">Pilihan Ganda</div>
                                    <div class="badge badge-soft-primary font-size-12" v-else>Essay</div>
                                </div>
                                <div class="d-block">
                                    <div class="badge badge-soft-secondary font-size-12">Point : {{detailQuestion.point}}</div>
                                </div>
                            </div>
                            <div class="d-block my-3" v-html="detailQuestion.question">
                            </div>
                            <div class="d-block p-3 rounded bg-light mt-4">
                                <div class="mb-4">Jawaban</div>
                                <div class="d-block" v-if="detailQuestion.type == 'MULTIPLE_CHOICE'">
                                    <div class="card my-2" v-for="(option, index) in detailQuestion.options" :key="index">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <input type="radio" class="me-2 border-muted" style="scale: 1.7" name="answer" :id="'answer-' + index" :value="alphabet[index]" v-model="form.answer" @change="saveQuiz(false)">
                                                <label style="width: 100%" class="mb-0 p-2" :for="'answer-' + index">
                                                    <div v-html="option"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block" v-else>
                                    <textarea class="form-control mt-1" rows="5" placeholder="Masukan jawabanmu disini..." v-model="form.answer"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-secondary me-2" v-if="detailQuestion.number > 1" @click="setQuestion(detail.question[detailQuestion.number - 2])">Sebelumnya</button>
                                <button type="button" class="btn btn-primary" @click="saveQuiz()"><span v-if="detailQuestion.number < detail.question.length">Selanjutnya</span><span v-else>Simpan</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex h5 mb-3 align-items-center"><i class="ri ri-timer-line font-size-20 me-2"></i>Waktu</div>
                            <vue-countdown-timer
                                @start_callback="startCallBack"
                                @end_callback="endCallBack"
                                :start-time="detail.start_quiz"
                                :end-time="detail.expired_time"
                                :interval="1000" v-if="!waiting">
                                <div slot="countdown" slot-scope="scope" class="row bg-light m-0 p-3 rounded text-center">
                                    <div class="col-md-4">
                                        <div>Jam</div>
                                        <div class="h3 mt-1">{{scope.props.hours}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <div>Menit</div>
                                        <div class="h3 mt-1">{{scope.props.minutes}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <div>Detik</div>
                                        <div class="h3 mt-1">{{scope.props.seconds}}</div>
                                    </div>
                                </div>
                            </vue-countdown-timer>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex h5 mb-3 align-items-center"><i class="ri ri-dashboard-line font-size-20 me-2"></i>Daftar Soal</div>
                            <div class="d-block bg-light p-3 rounded text-center">
                                <div v-if="detail.question">
                                    <button type="button" class="btn btn-square" style="margin: 2px" v-for="(row, index) in detail.question" @click="setQuestion(row)" :class="{'bg-white btn-outline-primary text-primary': row.number == detailQuestion.number, 'btn-white': row.number != detailQuestion.number && !row.your_answer, 'btn-primary': row.your_answer}">{{index + 1}}</button>
                                </div>
                                <div class="d-flex text-muted justify-content-center" v-else><i class="ri ri-information-line me-2"></i>Belum ada soal pertanyaan</div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-grid">
                                <button type="button" class="btn btn-primary" @click="stopQuiz()">Selesai</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'LessonDetail',
    metaInfo: {
        title: "Overview Kuis",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            detail: {question: []},
            question: [],
            alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
            waiting: false,
            form: {
                id: '',
                answer_id: '',
                answer: ''
            },
            detailQuestion: {}
        }
    },
    created() {
        this.startQuiz()
    },
    methods: {
        startCallBack: function () {
            console.log('waktu dimulai');
        },
        endCallBack: function () {
            this.stopQuiz();
        },
        startQuiz: function() {
            this.waiting = true
            let uri     = process.env.VUE_APP_APIHOST+'student/quiz/start'

            this.dataFetch  = true;
            this.$http.get(uri,{
                params : {
                    lessons_id : this.$route.params.id
                }
            }).then(res => {
                if(res.data.status) {
                    this.waiting = false
                    let getResponse     = res.data.data;
                    this.detail         = getResponse;

                    if (this.detail.question.length > 0) {
                        this.setQuestion(this.detail.question[0])
                    }
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push({ name: 'learningpath', params: {id: this.$route.params.courses_id} });
                        }
                    });
                }
            });
        },
        setQuestion(data) {
            this.detailQuestion = data
            this.form.answer_id = this.detail.answer_id
            this.form.id = data.id
            this.form.answer = data.your_answer
        },
        saveQuiz(next=true) {
            let formData = new FormData();

            for (var index in this.form) {
                formData.append(index, this.form[index]);
            }

            this.$http.post(process.env.VUE_APP_APIHOST + 'student/quiz/save', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.data.status) {
                    this.$showToast(response.data.message, 'success')
                    var pos = this.detailQuestion.number < this.detail.question.length ? this.detailQuestion.number - 1 : this.detail.question.length-1
                    this.detail.question[pos].your_answer = this.form.answer
                    if (pos < this.detail.question.length - 1) {
                        this.form.answer = null
                        this.setQuestion(this.detail.question[(next ? pos + 1 : pos)])
                    }
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                }
                this.waiting = false;
            })
            .catch(function () {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please check your internet connection.',
                });
                this.waiting = false;
                return;
            });
        },
        stopQuiz() {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menyelesaikan kuis!',
                showCancelButton: true,
                confirmButtonText: 'Selesaikan!',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'student/quiz/stop', {
                            answer_id: this.form.answer_id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'quiz_overview' , params: {courses_id: this.$route.params.courses_id, id: this.$route.params.id}});
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        }
    },
}
</script>