<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-8">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Detail Pembelajaran</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard_student' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'learningpath', params: {id: this.$route.params.courses_id} }">Daftar Pembelajaran</router-link>
                                </li>
                                <li class="breadcrumb-item active">Detail Pembelajaran</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div class="d-block">
                                    <div class="card-title">{{detail.name}}</div>
                                    <div class="card-subtitle">{{detail.format}}</div>
                                </div>
                                <div class="d-block">
                                    <router-link class="btn btn-light" :to="{name: 'learningpath', params: {id: this.$route.params.courses_id}}">Kembali</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="card bg-primary" v-if="detail.assignment && detail.assignment.score">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <label class="label mb-0 text-white">Nilai</label>
                                <div class="h4 mb-0 text-white">{{detail.assignment.score}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-block">
                                <div class="mb-4 mt-2 rounded bg-soft-info text-info p-2 d-flex align-items-center"><i class="ri ri-information-line me-2 font-size-16"></i>Catatan : <div class="ms-2" v-html="detail.note"></div></div>
                                <div class="mt-3" v-html="detail.description" v-if="detail.format == 'TEXT'"></div>
                                <iframe :src="detail.attachment" v-if="detail.format == 'FILE'" width="100%" height="1000"></iframe>
                                <div v-if="detail.format == 'AUDIO'" class="col-md-12">
                                    <vue-plyr>
                                    <div class="plyr__video-embed">
                                        <iframe
                                        :src="detail.attachment"
                                        width="100%"
                                        height="500"
                                        
                                        allowfullscreen
                                        allowtransparency
                                        allow="autoplay"
                                        ></iframe>
                                    </div>
                                    </vue-plyr>
                                </div>
                                <div v-if="detail.format == 'YOUTUBE'" class="col-md-12">
                                    <vue-plyr>
                                        <div class="plyr__video-embed">
                                            <iframe
                                            :src="detail.description"
                                            allowfullscreen
                                            allowtransparency
                                            allow="autoplay"
                                            ></iframe>
                                        </div>
                                    </vue-plyr>
                                </div>                                    
                                <div v-if="detail.format == 'VIDEO'" class="col-md-12">
                                    <vue-plyr>
                                        <video
                                            controls
                                            crossorigin
                                            playsinline>
                                            <source
                                                size="1080"
                                                :src="detail.attachment"
                                                type="video/mp4"
                                            />
                                            </video>
                                    </vue-plyr>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" v-if="detail.references">
                            <div class="d-flex"><i class="ri ri-links-line me-2"></i>Referensi : <a :href="detail.references" class="text-primary ms-2" target="_blank">{{detail.references}}</a></div>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="card-header">
                                <div class="card-title mb-0">Jawaban</div>
                            </div>
                            <div class="card-body">
                                <div class="form-group mb-3">
                                    <label class="label">Deskripsi</label>
                                    <ckeditor :config="editorConfig" v-model="form.description" class="mt-2" :class="{ 'is-invalid': submitted && $v.form.description.$error }"></ckeditor>
                                    <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">Deskripsi wajib diisi!</div>
                                </div>
                                <div class="form-group">
                                    <label class="label">Lampiran File</label>
                                    <input type="file" class="form-control" @change="getFile">
                                    <div class="d-flex bg-light rounded p-3 mt-3" v-if="detail.assignment && detail.assignment.attachment">
                                        <i class="ri ri-search-eye-line"></i> <a :href="detail.assignment.attachment" class="ms-2" target="_blank">Lihat File Sebelumnya</a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="d-flex justify-content-end align-items-center">
                                    <router-link class="btn btn-secondary" :to="{name: 'learningpath', params: {id: this.$route.params.courses_id}}">Kembali</router-link>
                                    <button class="btn btn-primary ms-2" type="submit" v-if="!detail.assignment || !detail.assignment.score">Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    required
} from "vuelidate/lib/validators";
export default {
    name: 'LessonDetail',
    metaInfo: {
        title: "Detail Pembelajaran",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            detail: {},
            form: {
                lessons_id: this.$route.params.id,
                description: ''
            },
            submitted: false,
            editorConfig: {
                versionCheck: false,
                toolbar: [{
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                }]
            },
        }
    },
    created() {
        this.fetchDetail()
    },
    validations: {
        form: {
            description: {
                required
            },
        }
    },
    methods: {
        fetchDetail: function() {
            let uri     = process.env.VUE_APP_APIHOST+'student/lessons/detail';

            this.dataFetch  = true;                
            this.$http.get(uri,{
                params : {
                    id : this.$route.params.id
                }
            }).then(res => {
                if(res.data.status) {
                    let getResponse = res.data.data;
                    this.detail       = getResponse;
                    if (getResponse.assignment)
                    this.form.description = getResponse.assignment.description
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push({ name: 'learningpath', params: {id: this.$route.params.courses_id} });
                        }
                    });
                }
            });
        },
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'student/assignment/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.fetchDetail()
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        getFile(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.form.attachment = file;
            }
        },
    },
}
</script>