<template>
<div class="vertical-menu">
    <div data-simplebar class="h-100 sidebar-shadow">
        <!--- Sidemenu -->
        <div id="sidebar-menu" class="">
            <ul v-for="(item, index) in listMenu" :key="index" class="metismenu list-unstyled side-menu">
                <li class="menu-title">
                    {{index}}
                </li>

                <li v-for="(menu, indexMenu) in item" :key="indexMenu" :class="menu.parent == 1 ? menu.url.includes(activeClass) ? 'mm-active': '' : activeClass == menu.url ? 'mm-active':''" v-on:click="setCurrent(menu.url)">
                    <router-link v-if="menu.parent != 1" :to="{name : menu.url}" class="waves-effect">
                        <i :class="menu.icon"></i>
                        <span>{{menu.name}}</span>
                    </router-link>

                    <a v-if="menu.parent == 1" href="javascript: void(0);" class="has-arrow waves-effect">
                        <i :class="menu.icon"></i>
                        <span>{{menu.name}}</span>
                    </a>

                    <ul v-if="menu.parent == 1" class="sub-menu" aria-expanded="false">
                        <li v-for="(subMenu, indexSubMenu) in menu.sub_menu" :key="indexSubMenu" v-on:click="setCurrent(subMenu.url)">
                            <router-link :to="{name : subMenu.url}" :class="activeClass == subMenu.url ? 'active':''">{{subMenu.name}}</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- Sidebar -->
    </div>
</div>
</template>

<script>
export default {
    name: 'Sidebar',
    data() {
        return {
            activeClass: this.$route.name,
            lastMenu: "",
            listMenu: []
        }
    },
    created() {
        this.getMenu();
    },
    methods: {
        setCurrent: function (params = "") {
            this.activeClass = params;
        },
        getMenu: function () {
            let uri = process.env.VUE_APP_APIHOST + 'user/menu';

            this.$http.get(uri).then(response => {
                this.listMenu = response.data.list_menu;
                this.lastMenu = response.data.last_menu;

                let app = document.createElement('script')
                app.setAttribute('src', 'assets/js/appAdmin.js')
                document.head.appendChild(app)
            });
        },
    },
}
</script>
