<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-8">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Detail Pembelajaran</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard_student' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'learningpath', params: {id: this.$route.params.courses_id} }">Daftar Pembelajaran</router-link>
                                </li>
                                <li class="breadcrumb-item active">Detail Pembelajaran</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div class="d-block">
                                    <div class="card-title">{{detail.name}}</div>
                                    <div class="card-subtitle">{{detail.format}}</div>
                                </div>
                                <div class="d-block">
                                    <a class="btn btn-light me-2" :href="detail.attachment" v-if="detail.format == 'FILE' && detail.attachment" download>Download File</a>
                                    <router-link class="btn btn-light" :to="{name: 'learningpath', params: {id: this.$route.params.courses_id}}">Kembali</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-block">
                                <div class="mb-4 mt-2 rounded bg-soft-info text-info p-2 d-flex align-items-center" v-if="detail.note"><i class="ri ri-information-line me-2 font-size-16"></i>Catatan : <div class="ms-2" v-html="detail.note"></div></div>
                                <div class="mt-3" v-html="detail.description" v-if="detail.format == 'TEXT'"></div>
                                <iframe :src="detail.attachment" v-if="detail.format == 'FILE'" width="100%" height="1000"></iframe>
                                <div v-if="detail.format == 'AUDIO'" class="col-md-12">
                                    <vue-plyr>
                                    <div class="plyr__video-embed">
                                        <iframe
                                        :src="detail.attachment"
                                        width="100%"
                                        height="500"
                                        
                                        allowfullscreen
                                        allowtransparency
                                        allow="autoplay"
                                        ></iframe>
                                    </div>
                                    </vue-plyr>
                                </div>
                                <div v-if="detail.format == 'YOUTUBE'" class="col-md-12">
                                    <vue-plyr>
                                        <div class="plyr__video-embed">
                                            <iframe
                                            :src="detail.description"
                                            allowfullscreen
                                            allowtransparency
                                            allow="autoplay"
                                            ></iframe>
                                        </div>
                                    </vue-plyr>
                                </div>                                    
                                <div v-if="detail.format == 'VIDEO'" class="col-md-12">
                                    <vue-plyr>
                                        <video
                                            controls
                                            crossorigin
                                            playsinline>
                                            <source
                                                size="1080"
                                                :src="detail.attachment"
                                                type="video/mp4"
                                            />
                                            </video>
                                    </vue-plyr>
                                </div>
                                <a v-if="detail.format == 'LINK'" :href="detail.description" class="d-flex align-items-center alert alert-info w-100" target="_blank" style="font-weight: bold;"><i class="ri ri-links-line me-2"></i> Kunjungi Link URL</a>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex"><i class="ri ri-links-line me-2"></i>Referensi : <a :href="detail.references" class="text-primary ms-2" target="_blank">{{detail.references}}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'LessonDetail',
    metaInfo: {
        title: "Detail Pembelajaran",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            detail: {}
        }
    },
    created() {
        this.fetchDetail()
    },
    methods: {
        fetchDetail: function() {
            let uri     = process.env.VUE_APP_APIHOST+'student/lessons/detail';

            this.dataFetch  = true;                
            this.$http.get(uri,{
                params : {
                    id : this.$route.params.id
                }
            }).then(res => {
                if(res.data.status) {
                    let getResponse = res.data.data;
                    this.detail       = getResponse;
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push({ name: 'learningpath', params: {id: this.$route.params.courses_id} });
                        }
                    });
                }
            });
        },
    },
}
</script>