<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-8">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Overview Kuis</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard_student' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'learningpath', params: {id: this.$route.params.courses_id} }">Daftar Pembelajaran</router-link>
                                </li>
                                <li class="breadcrumb-item active">Overview Kuis</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div class="d-block">
                                    <div class="card-title">{{detail.name}}</div>
                                    <div class="card-subtitle">{{detail.format}}</div>
                                </div>
                                <div class="d-block">
                                    <router-link class="btn btn-light" :to="{name: 'learningpath', params: {id: this.$route.params.courses_id}}">Kembali</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-block">
                                        Jadwal
                                    </div>
                                    <div class="d-block rounded p-2 mt-2 bg-light text-dark font-size-15" v-if="detail.is_expired">
                                        {{detail.start_date | formatDate('MMM DD, YYYY hh:mm')}} s/d {{detail.end_date | formatDate('MMM DD, YYYY hh:mm')}}
                                    </div>
                                    <div class="d-block rounded p-2 mt-2 bg-light text-muted font-size-15" v-else>
                                        Tidak ada tanggal kedaluarsa
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-block">
                                        Durasi
                                    </div>
                                    <div class="d-block rounded p-2 mt-2 bg-light text-dark font-size-15">
                                        {{detail.duration}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8" v-if="!quiz.is_started">
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-md-10">
                                    <div class="d-flex text-muted align-items-center"><i class="mdi mdi-information me-2 font-size-20"></i> Kamu belum mengerjakan kuis ini, ayo mulai sekarang!</div>
                                </div>
                                <div class="col-md-2">
                                    <div class="d-grid">
                                        <router-link class="btn btn-primary" :to="{name: 'quiz', params: {courses_id: $route.params.courses_id, id: $route.params.id}}">Mulai Kuis</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8" v-if="quiz.is_done && quiz.score.wait == 0">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" v-if="quiz.is_done">
                                <div class="col-md-4">
                                    <div class="p-3 rounded bg-light text-center">
                                        <div class="font-size-16">Benar</div>
                                        <div class="h2 mt-3">{{quiz.score.correct}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="p-3 rounded bg-light text-center">
                                        <div class="font-size-16">Salah</div>
                                        <div class="h2 mt-3">{{quiz.score.wrong}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="p-3 rounded bg-light text-center">
                                        <div class="font-size-16">Nilai</div>
                                        <div class="h2 mt-3">{{quiz.score.score}}</div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <div class="d-flex alert alert-success justify-content-center align-items-center" v-if="quiz.score.have_passed"><i class="ri ri-checkbox-circle-fill me-2 font-size-18"></i>Selamat anda sudah lulus, silakan buka materi ajar selanjutnya</div>
                                    <div class="d-flex alert alert-warning" v-else>
                                        <div><i class="ri ri-error-warning-fill me-2 font-size-18 mt-2"></i></div>
                                        <div>Kamu belum <strong>KOMPETEN</strong> pada kuis ini, Silahkan untuk mempelajari materi dibawah ini sebelum melakukan Remedial.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block p-3 bg-light rounded text-center text-muted" v-else>
                                Penilaian belum tersedia, silahkan kerjakan kuis terlebih dahulu
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8" v-if="quiz.is_done && quiz.score.wait == 0">
                    <div class="card">
                        <div class="card-body mb-0">
                            <div class="h5 mb-0">Pembahasan</div>
                        </div>
                        <hr class="my-0" />
                        <div class="card-body">
                            <div v-html="detail.discussion" v-if="detail.discussion"></div>
                            <div class="d-block p-3 bg-light rounded text-muted" v-else>
                                Pembahasan belum tersedia.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8" v-if="quiz.is_done && quiz.score.wait > 0">
                    <div class="alert alert-danger d-flex align-items-center" role="alert">
                        <i class="ri ri-information-line me-2 font-size-16"></i> Nilai belum tersedia untuk saat ini.
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-block">
                                <div class="mb-4 mt-2 rounded bg-soft-info text-info p-2 d-flex align-items-center"><i class="ri ri-information-line me-2 font-size-16"></i>Catatan : <div class="ms-2" v-html="detail.note"></div></div>
                                <div class="mt-3" v-html="detail.description" v-if="detail.format == 'TEXT'"></div>
                                <iframe :src="detail.attachment" v-if="detail.format == 'FILE'" width="100%" height="1000"></iframe>
                                <div v-if="detail.format == 'AUDIO'" class="col-md-12">
                                    <vue-plyr>
                                    <div class="plyr__video-embed">
                                        <iframe
                                        :src="detail.attachment"
                                        width="100%"
                                        height="500"
                                        
                                        allowfullscreen
                                        allowtransparency
                                        allow="autoplay"
                                        ></iframe>
                                    </div>
                                    </vue-plyr>
                                </div>
                                <div v-if="detail.format == 'YOUTUBE'" class="col-md-12">
                                    <vue-plyr>
                                        <div class="plyr__video-embed">
                                            <iframe
                                            :src="detail.description"
                                            allowfullscreen
                                            allowtransparency
                                            allow="autoplay"
                                            ></iframe>
                                        </div>
                                    </vue-plyr>
                                </div>                                    
                                <div v-if="detail.format == 'VIDEO'" class="col-md-12">
                                    <vue-plyr>
                                        <video
                                            controls
                                            crossorigin
                                            playsinline>
                                            <source
                                                size="1080"
                                                :src="detail.attachment"
                                                type="video/mp4"
                                            />
                                            </video>
                                    </vue-plyr>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex"><i class="ri ri-links-line me-2"></i>Referensi : <a :href="detail.references" class="text-primary ms-2" target="_blank">{{detail.references}}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'LessonDetail',
    metaInfo: {
        title: "Overview Kuis",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            detail: {},
            quiz: {
                is_done: true
            }
        }
    },
    created() {
        this.fetchDetail()
        this.overview()
    },
    methods: {
        fetchDetail: function() {
            let uri     = process.env.VUE_APP_APIHOST+'student/lessons/detail';

            this.dataFetch  = true;                
            this.$http.get(uri,{
                params : {
                    id : this.$route.params.id
                }
            }).then(res => {
                if(res.data.status) {
                    let getResponse = res.data.data;
                    this.detail       = getResponse;
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push({ name: 'learningpath', params: {id: this.$route.params.courses_id} });
                        }
                    });
                }
            });
        },
        overview: function() {
            let uri     = process.env.VUE_APP_APIHOST+'student/quiz/overview';

            this.dataFetch  = true;                
            this.$http.get(uri,{
                params : {
                    lessons_id : this.$route.params.id
                }
            }).then(res => {
                if(res.data.status) {
                    let getResponse = res.data.data;
                    this.quiz       = getResponse;
                    if (getResponse.is_started && !getResponse.is_done) {
                        this.$router.push({ name: 'quiz', params: {coures_id: this.$route.params.courses_id, id: this.$route.params.id} });
                    }
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push({ name: 'learningpath', params: {id: this.$route.params.courses_id} });
                        }
                    });
                }
            });
        },
    },
}
</script>