<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4 text-muted">Menampilkan
                        {{ filterItems(dataList.list).length }} siswa.</h4>
                    <div class="table-responsive" v-if="filterItems(dataList.list).length > 0">
                        <table class="table table-centered table-bordered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th rowspan=2 class="text-center" style="width: 3%">Soal</th>
                                    <th class="text-center" colspan=2>Daya Beda</th>
                                    <th class="text-center" colspan=2>Tingkat Kesukaran</th>
                                    <th class="text-center" colspan="3">Efektivitas Pengecoh</th>
                                    <th rowspan=2 class="text-center">Kesimpulan Akhir</th>
                                </tr>
                                <tr>
                                    <td class="text-center">Koefisien</td>
                                    <td class="text-center">Keterangan</td>
                                    <td class="text-center">Koefisien</td>
                                    <td class="text-center">Keterangan</td>
                                    <td class="text-center">Frekuensi Pilian Jawaban</td>
                                    <td class="text-center">Kunci Jawaban</td>
                                    <td class="text-center">Keterangan</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in filterItems(dataList.list)" :key="row.id">
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center">{{ row.status.difference.coefficient }}</td>
                                    <td class="text-center">{{ row.status.difference.description }}</td>
                                    <td class="text-center">{{ row.status.level.coefficient }}</td>
                                    <td class="text-center">{{ row.status.level.description }}</td>
                                    <td class="text-center">
                                        <div class="d-flex justify-content-between">
                                            <div v-for="distractor, indexDistractor in row.distractor" :key="indexDistractor" class="border rounded" :class="{'me-2': indexDistractor < (row.distractor.length - 1)}">
                                                <div class="d-flex">
                                                    <div class="px-3 py-2 fw-bold">
                                                        {{ distractor.alphabet }}
                                                    </div>
                                                    <div class="px-3 py-2 border-start fw-bold bg-light">
                                                        {{ distractor.total }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">{{ row.answer }}</td>
                                    <td class="text-center fw-bold">{{ row.distractor_description }}</td>
                                    <td class="text-center fw-bold">{{ row.conclusion }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <template v-if="!dataFetch">
                        <div class="bg-light p-3 rounded text-center text-muted"
                            v-if="!params.courses_id || !params.quiz_id">
                            Silahkan untuk memilih <b>filter kelas, topik, dan kuis</b> untuk melihat nilai siswa.
                        </div>
                        <div class="bg-light p-3 rounded text-center text-muted"
                            v-if="params.courses_id && params.quiz_id && filterItems(dataList.list).length == 0">
                            Data tidak ditemukan
                        </div>
                    </template>
                </div>
                <!-- end card-body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>
<script>
export default {
    name: 'AnalysisQuizList',
    props: {
        keyword: '',
        params: {
            type: Object,
            default: {
                courses_id: '',
                topic_id: '',
                quiz_id: '',
            }
        }
    },
    metaInfo: {
        title: "Laporan Analisis Butir",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            dataFetch: false,
            dataList: {
                question: [],
                list: []
            },
        }
    },
    component: {},
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            this.dataList = {
                question: [],
                list: []
            }
            let uri = process.env.VUE_APP_APIHOST + 'teacher/report/analysis-quiz';

            this.dataFetch = true;
            this.params.type = 'quest'
            this.$http.get(uri, {
                params: this.params
            }).then(res => {
                this.dataFetch = false;
                if (res.data.status) {
                    this.dataList.list = res.data.data;
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            if (data) {
                if (app.keyword) {
                    return data.filter(function (result) {
                        let regex = new RegExp('(' + app.keyword + ')', 'i');
                        return result.identity_number.toString().match(regex) || result.name.match(regex);
                    })
                } else {
                    return data;
                }
            } else {
                return []
            }
        },
    },
}
</script>