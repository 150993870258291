<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Kelas Online</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard_student' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item active">Kelas Online</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div class="card-title mb-4 text-muted">Filter</div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 mb-2">
                                    <label>Tahun Periode</label>
                                    <select class="form-select border-muted" v-model="params.year_id" @change="getData()">
                                        <option value="">Semua Tahun Periode</option>
                                        <option v-for="row in periodeList" :value="row.id">{{ row.name }} {{ row.active ==
                                            '1' ? '(Aktif)' : ''
                                        }}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <label>Mata Pelajaran</label>
                                    <select class="form-select border-muted" v-model="params.course_category_id"
                                        @change="getData()">
                                        <option value="">Semua Mata Pelajaran</option>
                                        <option v-for="row in categoryList" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-5 mb-2">
                                    <label>Kata Kunci</label>
                                    <input type="text" class="form-control border-muted"
                                        placeholder="Cari siswa berdasarkan nis atau nama disini ..." v-model="searchQuery">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="filterItems(dataList).length > 0">
                <div class="col-md-6 col-xl-3" v-for="row in filterItems(dataList)" :key="row.id">
                    <!-- Simple card -->
                    <div class="card">
                        <img class="card-img-top img-fluid"
                            :src="row.cover ? row.cover : '~@/assets/images/placeholder.png'" alt="Card image cap">
                        <div class="card-body">
                            <h4 class="card-title ">{{ row.name }}</h4>
                            <h5 class="card-title text-muted ">{{ row.category }}</h5>
                            <!-- <p class="card-text">{{row.description}}</p> -->
                            <div class="d-flex align-items-center mb-2"><i
                                    class="ri ri-group-fill me-2"></i>{{ row.participant }} Anggota</div>
                            <div class="d-flex align-items-center"><i
                                    class="ri ri-user-2-fill me-2"></i>{{ row.teacher_name }}</div>
                            <div class="d-grid mt-4">
                                <router-link :to="{ name: 'learningpath', params: { id: row.id } }"
                                    class="btn btn-primary waves-effect waves-light">Masuk Kelas Online</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="filterItems(dataList).length == 0">
                <div class="col-md-12">
                    <div class="d-block rounded bg-white p-3 text-center">
                        Data tidak ditemukan.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Courses',
    metaInfo: {
        title: "Daftar Kelas Online",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            dataList: [],
            categoryList: [],
            periodeList: [],
            searchQuery: '',
            params: {
                year_id: '',
                course_category_id: ''
            },
        }
    },
    created() {
        this.getData();
        this.getYear();
        this.getCategory();
    },
    methods: {
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'student/courses/list';

            this.dataList = [];
            this.dataFetch = true;
            this.$http.get(uri, { params: this.params }).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
            });
        },
        getYear: function () {
            let uri = process.env.VUE_APP_APIHOST + 'year/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.periodeList = res.data.data;
                }
            });
        },
        getCategory: function () {
            let uri = process.env.VUE_APP_APIHOST + 'category/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.categoryList = res.data.data;
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            if (data) {
                return data.filter(function (result) {
                    let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                    return result.name.match(regex) || result.name.match(regex);
                })
            } else {
                return [];
            }
        }
    },
}
</script>