import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class'
import Axios from 'axios'

// --------------------------- GENERAL ROUTES --------------------------------
import Skeleton from '../components/Skeleton.vue'
// Authentication routes
import Signin from '../views/authentication/Signin.vue'
import Signout from '../views/authentication/Signout.vue'
import TermCondition from '../views/TermCondition.vue'
// --------------------------- END GENERAL ROUTES --------------------------------

// --------------------------- ADMINISTRATOR ROUTES --------------------------------
import HomeAdministrator from '../views/administrator/Dashboard.vue'

// --------------------------- PROGRAM ROUTES --------------------------------
import Program from '../views/administrator/program/Main.vue'
import FormProgram from '../views/administrator/program/Form.vue'

// --------------------------- PROGRAM STUDY ROUTES --------------------------------
import ProgramStudy from '../views/administrator/program-study/Main.vue'
import FormProgramStudy from '../views/administrator/program-study/Form.vue'

// --------------------------- PERIOD ROUTES --------------------------------
import Period from '../views/administrator/period/Main.vue'
import FormPeriod from '../views/administrator/period/Form.vue'

// --------------------------- PERIOD ROUTES --------------------------------
import Curriculum from '../views/administrator/curriculum/Main.vue'
import FormCurriculum from '../views/administrator/curriculum/Form.vue'

// --------------------------- EDUCATION ROUTES --------------------------------
import Education from '../views/administrator/education/Main.vue'
import FormEducation from '../views/administrator/education/Form.vue'

// --------------------------- GRADE ROUTES --------------------------------
import Grade from '../views/administrator/grade/Main.vue'
import FormGrade from '../views/administrator/grade/Form.vue'

// --------------------------- STUDY GROUP ROUTES --------------------------------
import StudyGroup from '../views/administrator/study-group/Main.vue'
import FormStudyGroup from '../views/administrator/study-group/Form.vue'

// --------------------------- COURSES CATEGORY ROUTES --------------------------------
import CoursesCategory from '../views/administrator/courses-category/Main.vue'
import FormCoursesCategory from '../views/administrator/courses-category/Form.vue'

// --------------------------- MANAGE STUDENT ROUTES --------------------------------
import Student from '../views/administrator/student/Main.vue'
import FormStudent from '../views/administrator/student/Form.vue'

// --------------------------- MANAGE TEACHER ROUTES --------------------------------
import Teacher from '../views/administrator/teacher/Main.vue'
import FormTeacher from '../views/administrator/teacher/Form.vue'

// --------------------------- MANAGE COURSES ROUTES --------------------------------
import Courses from '../views/administrator/courses/Main.vue'
import FormCourses from '../views/administrator/courses/Form.vue'
// --------------------------- END ADMINISTRATOR ROUTES --------------------------------

// --------------------------- START TEACHER ROUTES --------------------------------
import DashboardTeacher from '@/views/teacher/Dashboard'

// --------------------------- COURSES ROUTES --------------------------------
import CoursesTeacher from '@/views/teacher/courses/Main'
import CoursesDetailTeacher from '@/views/teacher/courses/Detail'
import FormLessons from '@/views/teacher/courses/FormLessons'
import DetailLessons from '@/views/teacher/courses/DetailLessons'
import ManageQuiz from '@/views/teacher/courses/quiz/ManageQuiz'
import ManageAssignment from '@/views/teacher/courses/assignment/ManageAssignment'

// --------------------------- REPORT ROUTES --------------------------------
import ReportFinalExam from '@/views/teacher/report/FinalExam'
import ReportAnalysisQuiz from '@/views/teacher/report/AnalysisQuiz'
import ReportRecapScore from '@/views/teacher/report/RecapScore'

// --------------------------- END TEACHER ROUTES --------------------------------

// --------------------------- START STUDENT ROUTES --------------------------------
import CoursesStudent from '@/views/student/courses/Main'
import CoursesDetailStudent from '@/views/student/courses/Detail'
import DetailLessonsStudent from '@/views/student/courses/DetailLessons'
import QuizOverview from '@/views/student/courses/quiz/Overview'
import Quiz from '@/views/student/courses/quiz/Quiz'
import Assignment from '@/views/student/courses/Assignment'
// --------------------------- END STUDENT ROUTES --------------------------------

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
    meta: {
      transition  : 'fade'
    }
  },
  {
    path: '/signout',
    name: 'signout',
    component: Signout,
    meta: {
      transition  : 'fade',
    }
  },
  {
    path: '/term-condition',
    name: 'term.condition',
    component: TermCondition,
    meta: {
      transition  : 'fade',
    }
  },

  // ADMINISTRATOR ROUTES
  // DASHBOARD ROUTES
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomeAdministrator,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // PROGRAM ROUTES
  {
    path: '/program',
    name: 'program',
    component: Program,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/program/form',
    name: 'add_program',
    component: FormProgram,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/program/form/:id',
    name: 'edit_program',
    component: FormProgram,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // PROGRAM STUDY ROUTES
  {
    path: '/program-study',
    name: 'program_study',
    component: ProgramStudy,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/program-study/form',
    name: 'add_program_study',
    component: FormProgramStudy,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/program-study/form/:id',
    name: 'edit_program_study',
    component: FormProgramStudy,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // PERIOD ROUTES
  {
    path: '/period',
    name: 'period',
    component: Period,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/period/form',
    name: 'add_period',
    component: FormPeriod,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/period/form/:id',
    name: 'edit_period',
    component: FormPeriod,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // PERIOD ROUTES
  {
    path: '/curriculum',
    name: 'curriculum',
    component: Curriculum,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/curriculum/form',
    name: 'add_curriculum',
    component: FormCurriculum,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/curriculum/form/:id',
    name: 'edit_curriculum',
    component: FormCurriculum,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // EDUCATION ROUTES
  {
    path: '/education',
    name: 'education',
    component: Education,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/education/form',
    name: 'add_education',
    component: FormEducation,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/education/form/:id',
    name: 'edit_education',
    component: FormEducation,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // GRADE ROUTES
  {
    path: '/grade',
    name: 'grade',
    component: Grade,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/grade/form',
    name: 'add_grade',
    component: FormGrade,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/grade/form/:id',
    name: 'edit_grade',
    component: FormGrade,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // STUDY GROUP ROUTES
  {
    path: '/study-group',
    name: 'study_group',
    component: StudyGroup,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/study-group/form',
    name: 'add_study_group',
    component: FormStudyGroup,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/study-group/form/:id',
    name: 'edit_study_group',
    component: FormStudyGroup,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // COURSES CATEGORY ROUTES
  {
    path: '/courses-category',
    name: 'courses_category',
    component: CoursesCategory,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/courses-category/form',
    name: 'add_courses_category',
    component: FormCoursesCategory,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/courses-category/form/:id',
    name: 'edit_courses_category',
    component: FormCoursesCategory,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // MANAGE STUDENT ROUTES
  {
    path: '/student',
    name: 'student',
    component: Student,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/student/form',
    name: 'add_student',
    component: FormStudent,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/student/form/:id',
    name: 'edit_student',
    component: FormStudent,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // MANAGE TEACHER ROUTES
  {
    path: '/teacher',
    name: 'teacher',
    component: Teacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/teacher/form',
    name: 'add_teacher',
    component: FormTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/teacher/form/:id',
    name: 'edit_teacher',
    component: FormTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // MANAGE COURSES ROUTES
  {
    path: '/courses',
    name: 'courses',
    component: Courses,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/courses/form',
    name: 'add_courses',
    component: FormCourses,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  {
    path: '/courses/form/:id',
    name: 'edit_courses',
    component: FormCourses,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['administrator']
    }
  },
  // END AMINISTRATOR ROUTES
  // TEACHER ROUTES
  {
    path: '/dashboard-teacher',
    name: 'dashboard_teacher',
    component: DashboardTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/courses-teacher',
    name: 'courses_teacher',
    component: CoursesTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/manage-lessons/:id',
    name: 'manage_lessons',
    component: CoursesDetailTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/form-lessons/:courses_id/:section_id',
    name: 'add_lessons',
    component: FormLessons,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/form-lessons/:courses_id/:section_id/:id',
    name: 'edit_lessons',
    component: FormLessons,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/detail-lessons/:courses_id/:id',
    name: 'detail_lessons',
    component: DetailLessons,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/manage-quiz/:courses_id/:id',
    name: 'manage_quiz',
    component: ManageQuiz,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/manage-assignment/:courses_id/:id',
    name: 'manage_assignment',
    component: ManageAssignment,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/report/analysis_quiz',
    name: 'analysis_quiz',
    component: ReportAnalysisQuiz,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/report/analysis_question',
    name: 'analysis_question',
    component: DashboardTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/report/score_discussion',
    name: 'score_discussion',
    component: DashboardTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/report/report_score',
    name: 'report_score',
    component: ReportRecapScore,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  {
    path: '/report/raport',
    name: 'raport',
    component: DashboardTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['teacher']
    }
  },
  // END TEACHER ROUTES
  // STUDENT ROUTES
  {
    path: '/dashboard-student',
    name: 'dashboard_student',
    component: DashboardTeacher,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['student']
    }
  },
  {
    path: '/courses-student',
    name: 'courses_student',
    component: CoursesStudent,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['student']
    }
  },
  {
    path: '/learningpath/:id',
    name: 'learningpath',
    component: CoursesDetailStudent,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['student']
    }
  },
  {
    path: '/detail-lessons-student/:courses_id/:id',
    name: 'detail_lessons_student',
    component: DetailLessonsStudent,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['student']
    }
  },
  {
    path: '/quiz-overview/:courses_id/:id',
    name: 'quiz_overview',
    component: QuizOverview,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['student']
    }
  },
  {
    path: '/quiz/:courses_id/:id',
    name: 'quiz',
    component: Quiz,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['student']
    }
  },
  {
    path: '/assignment/:courses_id/:id',
    name: 'assignment',
    component: Assignment,
    meta: {
      transition: 'fade',
      layout: Skeleton,
      roles: ['student']
    }
  },
]
const vueBodyClass = new VueBodyClass(routes);
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages   = ['signin', 'signup', 'term.condition'];
  const authRequired  = !publicPages.includes(to.name);
  const token         = localStorage.getItem('lms_token');

  if (authRequired && token) {
      Axios.get(process.env.VUE_APP_APIHOST + 'user/info', {
          headers: {
              'Authorization': token
          }
      }).then(response => {
        if(response.data.logged_in) {
          let roles = response.data.role;
          
          if (to.fullPath != '/') {
            if(to.meta.roles.includes(roles)) {
                next();
            } else {
                alert("Akses tidak diperbolehkan");
                if(roles == 'teacher') {
                  next({ name: 'dashboard_teacher' });
                } else if (roles == 'administrator') {
                  next({ name: 'dashboard' });
                } else if (roles == 'student') {
                  next({ name: 'dashboard_student' });
                }
            }
          } else {
            if(roles == 'teacher') {
              next({ name: 'dashboard_teacher' });
            } else if (roles == 'administrator') {
              next({ name: 'dashboard' });
            } else if (roles == 'student') {
              next({ name: 'dashboard_student' });
            }
          }
        } else {
          alert("Sesi login telah habis !");
          localStorage.removeItem('id');
          localStorage.removeItem('lms_token');
          next({ name: 'signin' });
        }
    }).catch(function (error) {
      alert("Sesi login telah habis !");
      localStorage.removeItem('id');
      localStorage.removeItem('lms_token');
      next({ name: 'signin' });
    });
  } else if (authRequired && !token) {
    next({ name: 'signin' });
  } else {
    if(to.name == 'signin') {
        if(token) {
            next({ name: '/' });
        } else {
            next();
        }
    } else {
        if(!token) {
            if(to.name == 'signout') {
                next({ name: 'signin' });
            } else {
                next();
            }
        } else {
            next();
        }
    }
  }

  vueBodyClass.guard(to, next);
});

export default router
