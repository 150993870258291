<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Rekap Nilai Akhir</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item active">Rekap Nilai Akhir</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body border-bottom">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div class="card-title mb-4 text-muted">Filter</div>
                                <div v-if="params.courses_id">
                                    <button type="button" class="btn ms-2 btn-white" @click="exportExcel()"><i class="mdi mdi-download me-2"></i>Export</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 mb-2">
                                    <label>Kelas</label>
                                    <select class="form-select border-muted" v-model="params.courses_id"
                                        @change="getData()">
                                        <option value="">Semua Kelas</option>
                                        <option v-for="row in list_courses" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-8 mb-2">
                                    <label>Kata Kunci</label>
                                    <input type="text" class="form-control border-muted"
                                        placeholder="Cari siswa berdasarkan nis atau nama disini ..." v-model="searchQuery">
                                </div>
                            </div>
                        </div>
                        <div class="card-footer bg-white">
                            <ul class="nav nav-pills nav nav-pills">
                                <li class="nav-item"><button type="button" @click="changeTab('quiz')"
                                        class="nav-link" :class="{ 'active': type == 'quiz' }"
                                        style="cursor: pointer;"><i class="dripicons-checklist me-1 align-middle"> </i> Nilai Kuis</button></li>
                                <li class="nav-item"><a class="nav-link" @click="changeTab('assignment')"
                                        :class="{ 'active': type == 'assignment' }"
                                        style="cursor: pointer;"><i class="dripicons-clipboard me-1 align-middle"></i> Nilai Tugas</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <h4 class="card-title mb-4 text-muted" v-if="dataList.list.length">Menampilkan
                                        {{ filterItems(dataList.list).length }} siswa.</h4>
                                </div>
                                <div>
                                    <select class="form-control form-select mb-2" v-if="filterItems(dataList.list).length > 0" v-model="totalPage" @change="getData()">
                                        <option value="">Tampilkan Data</option>
                                        <option v-for="item in [10, 20, 30, 40, 50, 100]" :value="item">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive" v-if="filterItems(dataList.list).length > 0">
                                <table class="table table-centered table-bordered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th rowspan=3 class="text-center" style="width: 3%">No</th>
                                            <th rowspan=3 class="text-center" style="width: 15%">NIS</th>
                                            <th rowspan=3>Nama</th>
                                            <template v-for="(topics, index) in dataList.column.topics">
                                                <th :colspan="topics.child * 3" class="text-center"><div class="d-flex align-items-center justify-content-center"><span class="ellipsis">{{ topics.title }}</span> <i class="dripicons-information text-info mt-1 ms-2" v-tooltip:top="topics.title"></i></div></th>
                                            </template>
                                        </tr>
                                        <tr>
                                            <template v-for="(lessons, index) in dataList.column.lessons">
                                                <th colspan="3"><div class="d-flex align-items-center justify-content-center"><span class="ellipsis">{{ lessons.name }}</span> <i class="dripicons-information text-info mt-1 ms-2" v-tooltip:top="lessons.name"></i></div></th>
                                            </template>
                                        </tr>
                                        <tr>
                                            <template v-for="(lessons, index) in dataList.column.lessons">
                                                <th class="text-center">Nilai Angka</th>
                                                <th class="text-center">Nilai Huruf</th>
                                                <th class="text-center">Status</th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, index) in filterItems(pageOfItems)" :key="row.id">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center" style="font-weight: bold;">{{ row.identity_number }}
                                            </td>
                                            <td>{{ row.name }}</td>
                                            <template v-for="score in row.score">
                                                <td class="text-center" style="font-weight: bold;">{{
                                                    score.score }}</td>
                                                <td class="text-center" style="font-weight: bold;">{{
                                                    score.grade }}</td>
                                                <td class="text-center"
                                                    :class="{ 'text-success': score.status == '1', 'text-muted': score.status != '1' }"
                                                    style="font-weight: bold;">{{ score.status
                                                        == '1' ? 'Memenuhi' : 'Belum Memenuhi'
                                                    }}</td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <template v-if="!dataFetch">
                                <div class="bg-light p-3 rounded text-center text-muted"
                                    v-if="!params.courses_id">
                                    Silahkan untuk memilih <b>kelas</b> untuk melihat rekap nilai akhir siswa.
                                </div>
                                <div class="bg-light p-3 rounded text-center text-muted"
                                    v-if="params.courses_id && filterItems(dataList.list).length == 0">
                                    Data tidak ditemukan
                                </div>
                            </template>
                            <div class="d-flex justify-content-end mt-3">
                                <jw-pagination v-if="!dataFetch && dataList.list.length" :items="dataList.list" :pageSize="totalPage" @changePage="onChangePage"></jw-pagination>
                            </div>
                        </div>
                        <!-- end card-body -->
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div>
    </div>
</template>
<style scoped>
    .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
        display: inline-block;
    }
</style>
<script>
export default {
    name: 'RecapScore',
    metaInfo: {
        title: "Laporan Rekap Nilai Akhir",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            list_courses: [],
            pageOfItems: [],
            params: {
                courses_id: '',
            },
            searchQuery: '',
            dataFetch: false,
            dataList: {
                column: [],
                list: []
            },
            totalPage: 10,
            type: 'quiz'
        }
    },
    created() {
        this.getCourses()
    },
    methods: {
        getCourses: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/list';

            this.$http.get(uri, {}).then(res => {
                if (res.data.status) {
                    this.list_courses = res.data.data;
                }
            });
        },
        getData: function () {
            this.dataList = {
                column: [],
                list: []
            }
            if (this.params.courses_id) {
                let uri = process.env.VUE_APP_APIHOST + 'teacher/report/final-score';

                this.dataFetch = true;
                    this.params.with_score = false
                    this.params.type = this.type
                    this.$http.get(uri, {
                        params: this.params
                    }).then(res => {
                        this.dataFetch = false;
                        this.dataList.column = res.data.data.column;
                        this.dataList.list = res.data.data.transaction;
                    });
            }
        },
        filterItems: function (data) {
            var app = this;
            if (data) {
                if (app.searchQuery) {
                    return data.filter(function (result) {
                        let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                        return result.identity_number.toString().match(regex) || result.name.match(regex);
                    })
                } else {
                    return data;
                }
            } else {
                return []
            }
        },
        changeTab(tab) {
            this.type = tab
            this.getData()
        },
        onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },
        exportExcel: function() {
            this.params.with_score = false
            this.params.type = this.type
            let uri     = process.env.VUE_APP_APIHOST+'teacher/report/final-score/excel';

            this.$http.get(uri,{
                method: 'GET',
                responseType: 'blob',
                params: this.params
            }).then(response => {
                if(response.status == 200) {
                    const filename = this.type == 'quiz' ? 'Rekap Nilai Akhir Kuis' : 'Rekap Nilai Akhir Tugas';
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${filename}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            });
        },
    },
}
</script>