<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Analisis Butir Soal</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item active">Analisis Butir Soal</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body border-bottom">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div class="card-title mb-4 text-muted">Filter</div>
                                <div>
                                    <button type="button" class="btn ms-2 btn-white" @click="exportExcel()"><i
                                            class="mdi mdi-download me-2"></i>Export</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2 mb-2">
                                    <label>Kelas</label>
                                    <select class="form-select border-muted" v-model="params.courses_id"
                                        @change="getTopic()">
                                        <option value="">Semua Kelas</option>
                                        <option v-for="row in list_courses" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <label>Topik</label>
                                    <select class="form-select border-muted" v-model="params.topic_id"
                                        @change="getLessons()">
                                        <option value="">Semua Topik</option>
                                        <option v-for="row in list_topic" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-2 mb-2">
                                    <label>Kuis</label>
                                    <select class="form-select border-muted" v-model="params.quiz_id" @change="getData()">
                                        <option value="">Semua Kuis</option>
                                        <option v-for="row in list_quiz" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-5 mb-2">
                                    <label>Kata Kunci</label>
                                    <input type="text" class="form-control border-muted"
                                        placeholder="Cari siswa berdasarkan nis atau nama disini ..." v-model="searchQuery">
                                </div>
                            </div>
                        </div>
                        <div class="card-footer bg-white">
                            <ul class="nav nav-pills nav nav-pills">
                                <li class="nav-item me-2"><button type="button" @click="changeTab('AnalysisQuizAnswer')"
                                        class="nav-link" :class="{ 'active': currentTabComponent == 'AnalysisQuizAnswer', 'text-muted': currentTabComponent != 'AnalysisQuizAnswer' }"
                                        style="cursor: pointer;"><i class="dripicons-list me-1 align-middle"> </i> Jawaban Siswa</button></li>
                                <li class="nav-item"><a class="nav-link" @click="changeTab('AnalysisQuizScore')"
                                        :class="{ 'active': currentTabComponent == 'AnalysisQuizScore', 'text-muted': currentTabComponent != 'AnalysisQuizScore' }"
                                        style="cursor: pointer;"><i class="dripicons-checklist me-1 align-middle"></i> Nilai</a></li>
                                <li class="nav-item me-2"><button type="button" @click="changeTab('AnalysisQuizList')"
                                        class="nav-link" :class="{ 'active': currentTabComponent == 'AnalysisQuizList', 'text-muted': currentTabComponent != 'AnalysisQuizList' }"
                                        style="cursor: pointer;"><i class="dripicons-list me-1 align-middle"> </i> Laporan Butir</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 grid-margin stretch-card">
                    <!-- <keep-alive> -->
                    <component :is="currentTabComponent" :params="params" :keyword="searchQuery" ref="component">
                    </component>
                    <!-- </keep-alive> -->
                </div>
            </div>
            <!-- end row -->
        </div>
    </div>
</template>
<script>
import AnalysisQuizAnswer from '@/views/teacher/report/tabs/AnalysisQuizAnswer.vue';
import AnalysisQuizScore from '@/views/teacher/report/tabs/AnalysisQuizScore.vue';
import AnalysisQuizList from '@/views/teacher/report/tabs/AnalysisQuizList.vue';
export default {
    name: 'AnalysisQuiz',
    metaInfo: {
        title: "Laporan Analisis Butir",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            currentTabComponent: 'AnalysisQuizAnswer',
            list_courses: [],
            list_topic: [],
            list_quiz: [],
            params: {
                courses_id: '',
                topic_id: '',
                quiz_id: '',
            },
            searchQuery: '',
        }
    },
    components: {
        AnalysisQuizList,
        AnalysisQuizScore,
        AnalysisQuizAnswer
    },
    created() {
        this.getCourses()
    },
    methods: {
        changeTab(tab) {
            this.currentTabComponent = tab
        },
        getCourses: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/list';

            this.$http.get(uri, {}).then(res => {
                if (res.data.status) {
                    this.list_courses = res.data.data;
                }
            });
        },
        getTopic: function () {
            this.list_topic = []
            let uri = process.env.VUE_APP_APIHOST + 'teacher/lessons/list-topic';

            this.$http.get(uri, {
                params: {
                    courses_id: this.params.courses_id
                }
            }).then(res => {
                if (res.data.status) {
                    this.list_topic = res.data.data;
                }
            });
        },
        getLessons: function () {
            this.list_quiz = []
            let uri = process.env.VUE_APP_APIHOST + 'teacher/lessons/list-by-topic';

            this.$http.get(uri, {
                params: {
                    topic_id: this.params.topic_id,
                    type: 'QUIZ,FINAL_EXAM'
                }
            }).then(res => {
                if (res.data.status) {
                    this.list_quiz = res.data.data;
                }
            });
        },
        getData() {
            this.$refs.component.getData()
        },
        exportExcel() { }
    },
}
</script>