<template>
    <div class="row">
        <div class="col-md-12">
            <form class="mt-3" v-on:submit.prevent="onSubmit">
                <div class="card">
                    <div class="card-body">
                        <div class="h6 border-bottom pb-3">Pengaturan Kelas</div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="d-block rounded bg-light p-2 text-center mb-3">
                                    <a :href="previewCover" target="_blank">
                                        <img :src="previewCover" class="img-fluid rounded" width="100"/>
                                    </a>
                                    <input type="file" ref="cover" class="d-none" @change="previewFiles" accept="image/png, image/jpeg">
                                </div>
                                <button type="button" class="btn btn-block btn-light w-100" @click="$refs.cover.click()">Ganti Sampul</button>
                            </div>
                            <div class="col-md-9">
                                <div class="row form-group mb-3">
                                    <div class="col-md-6">
                                        <label class="label">Judul Kelas</label>
                                        <input type="text" class="form-control" placeholder="Masukan judul kelas" v-model="form.name" :class="{ 'is-invalid': submitted && $v.form.name.$error }" />
                                        <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required!</div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="label">Mata Pelajaran</label>
                                        <select class="form-control form-select" v-model="form.course_category_id">
                                            <option value="">Lihat Mata Pelajaran</option>
                                            <option value="" v-for="item in categoryList" :value="item.id">{{item.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="label">Deskripsi Kelas</label>
                                    <ckeditor :config="editorConfig" v-model="form.description" class="mt-2"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex card-footer justify-content-end">
                        <button type="submit" class="btn btn-primary">Simpan Perubahan</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'Settings',
    metaInfo: {
        title: "Pengaturan",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            categoryList: [],
            editorConfig: {
                versionCheck: false,
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript']
                ]
            },
            form: {
                id: '',
                name: '',
                course_category_id: '',
                description: '',
                cover: ''
            },
            previewCover: '',
            submitted: false
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            course_category_id: {
                required
            },
        }
    },
    mounted() {
        this.previewCover = this.$parent.detail?.cover
        this.form.id = this.$parent.detail?.id
        this.form.name = this.$parent.detail?.name
        this.form.course_category_id = this.$parent.detail?.course_category_id
        this.form.description = this.$parent.detail?.description

        this.getCategory()
    },
    methods: {
        getCategory: function () {
            let uri = process.env.VUE_APP_APIHOST + 'category/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.categoryList = res.data.data;
                }
            });
        },
        previewFiles(e) {
            const file = e.target.files[0];                
            var fsize = ((file.size/1024)/1024).toFixed(4); // MB
            
            if(fsize >= 5) {
                alert("File yang di upload tidak boleh lebih 5 MB");
            } else {
                this.previewCover = URL.createObjectURL(file);
                this.form.cover = file;
            }
        },
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/update', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$parent.getData()
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
    }
}
</script>