<template>
    <div class="card">
        <div class="card-body">
            <div class="card-subtitle mt-0 mb-3">Please complete the form below.</div>
            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="study_program_id">Jurusan</label>
                        <select class="form-select border-muted" id="study_program_id" name="study_program_id" v-model="form.study_program_id">
                            <option value="">Pilih Jurusan</option>
                            <option v-for="item in list_program_study" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.study_program_id.required" class="invalid-feedback">Jurusan is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="education_id">Pendidikan</label>
                        <select class="form-select border-muted" id="education_id" name="education_id" v-model="form.education_id">
                            <option value="">Pilih Pendidikan</option>
                            <option v-for="item in list_education" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.education_id.required" class="invalid-feedback">Pendidikan is required!</div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="curriculum_id">Kurikulum</label>
                        <select class="form-select border-muted" id="curriculum_id" name="curriculum_id" v-model="form.curriculum_id">
                            <option value="">Pilih Kurikulum</option>
                            <option v-for="item in list_curriculum" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.curriculum_id.required" class="invalid-feedback">Kurikulum is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="study_group_id">Rombongan Belajar</label>
                        <select class="form-select border-muted" id="study_group_id" name="study_group_id" v-model="form.study_group_id">
                            <option value="">Pilih Rombongan Belajar</option>
                            <option v-for="item in list_study_group" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.study_group_id.required" class="invalid-feedback">Rombongan Belajar is required!</div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="entry_year">Tahun Masuk</label>
                        <input type="number" v-model="form.entry_year" class="w-100 form-control" placeholder="Masukan tahun masuk"/>
                        <div v-if="submitted && !$v.form.entry_year.required" class="invalid-feedback">Tanggal Masuk is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="entry_date">Tanggal Masuk</label>
                        <date-picker v-model="form.entry_date" class="w-100" valueType="format" placeholder="yyyy-mm-dd"></date-picker>
                        <div v-if="submitted && !$v.form.entry_date.required" class="invalid-feedback">Tanggal Masuk is required!</div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="status_id">Status Siswa</label>
                        <select class="form-select border-muted" id="status_id" name="status_id" v-model="form.status_id">
                            <option value="">Pilih Status</option>
                            <option v-for="item in list_status" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.status_id.required" class="invalid-feedback">Status is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="type">Status</label>
                        <select class="form-select border-muted" id="type" name="type" v-model="form.type">
                            <option value="baru">Baru</option>
                            <option value="pindahan">Pindahan</option>
                        </select>
                        <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Type is required!</div>
                    </div>
                    <!-- <div class="col-md-6">
                        <label for="study_limit">Limit Studi</label>
                    <input type="text" class="form-control" id="study_limit" name="study_limit" :class="{ 'is-invalid': submitted && $v.form.study_limit.$error }" v-model="form.study_limit" placeholder="Enter Study of Limit ...">
                    <div v-if="submitted && !$v.form.study_limit.required" class="invalid-feedback">Limit Studi is required!</div>
                    </div> -->
                </div>
                <div class="d-flex justify-content-end">
                    <router-link :to="{name: 'student'}" class="btn btn-light mt-2">Kembali</router-link>
                    <button type="submit" class="btn btn-primary ms-2 mt-2"><span v-if="!form.id">Simpan & Lanjutkan</span><span v-if="form.id">Simpan</span></button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Academic',
        metaInfo: {
            title: 'Form Akademik Siswa',
            titleTemplate: '%s - Unfari E-learning'
        },
        props: {
            form: Object
        },
        mounted() {
            this.getCurriculum();
            this.getStatusUser();
            this.getStudyProgram();
            this.getStudyGroup();
            this.getEducation();
        },
        data() {
            return {
                list_program_study  : [],
                list_education      : [],
                list_curriculum     : [],
                list_study_group    : [],
                list_status         : [],
                submitted           : false,
                waiting             : false,
            }
        },
        validations: {
            form: {
                study_program_id: {
                    required
                },
                education_id: {
                    required
                },
                curriculum_id: {
                    required
                },
                study_group_id: {
                    required
                },
                status_id: {
                    required
                },
                entry_date: {
                    required
                },
                entry_year: {
                    required
                },
                type: {
                    required
                },
                // study_limit: {
                //     required
                // },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the forms',
                    });

                    this.waiting = false;
                    return;
                } else {
                    this.$emit("listenerChild", this.form);
                }
            },
            getCurriculum: function() {
                let uri     = process.env.VUE_APP_APIHOST+'curriculum/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_curriculum = res.data.data;
                    }
                });
            },
            getStudyProgram: function() {
                let uri     = process.env.VUE_APP_APIHOST+'program-study/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_program_study = res.data.data;
                    }
                });
            },
            getProgram: function() {
                let uri     = process.env.VUE_APP_APIHOST+'program/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_program = res.data.data;
                    }
                });
            },
            getEducation: function() {
                let uri     = process.env.VUE_APP_APIHOST+'education/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_education = res.data.data;
                    }
                });
            },
            getStatusUser: function() {
                let uri     = process.env.VUE_APP_APIHOST+'status-users/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_status = res.data.data;
                    }
                });
            },
            getStudyGroup: function() {
                let uri     = process.env.VUE_APP_APIHOST+'study-group/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_study_group = res.data.data;
                    }
                });
            },
        }
    }
</script>