import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VuePageTransition from 'vue-page-transition'
import axios from 'axios'
import CKEditor from 'ckeditor4-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VModal from 'vue-js-modal'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueCountdownTimer from 'vuejs-countdown-timer'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import JwPagination from 'jw-vue-pagination';

import "bootstrap-vue/dist/bootstrap-vue.css"
import { VBTooltip } from 'bootstrap-vue'

// import skeleton components
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'

Vue.config.productionTip  = false
Vue.prototype.$http       = axios

Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VuePageTransition)
Vue.use(CKEditor)
Vue.use(VModal, {componentName: 'modal'})
Vue.use(DatePicker)
Vue.use(VuePlyr, {
  plyr: {}
})
Vue.use(VueCountdownTimer)
Vue.use(VueToast);

Vue.component('sidebar', Sidebar)
Vue.component('navbar', Navbar)
Vue.component('multiselect', Multiselect)
Vue.component('jw-pagination', JwPagination);

// Add Global Auth Token
const token = localStorage.getItem('lms_token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

Vue.directive('tooltip', VBTooltip)

// filter format date
Vue.filter('formatDate', function(value, format='MM/DD/YYYY hh:mm') {
  if (value) {
      return moment(String(value)).format(format)
  }
});

// list function global
Vue.prototype.$arrayColumn = function(array, column) {
  return array.map(row => row[column])
}

Vue.prototype.$showToast = function(message, type='default') {
  this.$toast.open({
    message: message,
    type: type,
  });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
