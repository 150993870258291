<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div>
                            <h4 class="">{{detail.name}}</h4>
                            <h5 class="mb-sm-0">{{detail.category}}</h5>
                        </div>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard_teacher' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'courses_teacher' }">Kelas Online</router-link>
                                </li>
                                <li class="breadcrumb-item active">Daftar Pembelajaran</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div class="d-block">
                                    <ul class="nav nav-pills" role="tablist">
                                        <li class="nav-item waves-effect waves-light" @click="changeTab('lessons')">
                                            <a class="nav-link active" data-bs-toggle="tab" role="tab">
                                                <i class="dripicons-view-list me-1 align-middle"></i> <span class="d-none d-md-inline-block">Daftar Pembelajaran</span> 
                                            </a>
                                        </li>
                                        <li class="nav-item waves-effect waves-light" @click="changeTab('member')">
                                            <a class="nav-link" data-bs-toggle="tab" role="tab">
                                                <i class="dripicons-user me-1 align-middle"></i> <span class="d-none d-md-inline-block">Anggota</span>
                                            </a>
                                        </li>
                                        <li class="nav-item waves-effect waves-light" @click="changeTab('settings')">
                                            <a class="nav-link" data-bs-toggle="tab" role="tab">
                                                <i class="dripicons-gear me-1 align-middle"></i> <span class="d-none d-md-inline-block">Pengaturan</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="d-block">
                                    <router-link class="btn btn-light" :to="{name: 'courses_teacher'}">Kembali</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
                <!-- <keep-alive> -->
                <component :is="currentTabComponent"></component>
                <!-- </keep-alive> -->
            </div>
        </div>
    </div>
</template>
<script>
// import tab components
import lessons from '@/views/teacher/courses/tabs/Lessons.vue';
import member from '@/views/teacher/courses/tabs/Member.vue';
import settings from '@/views/teacher/courses/tabs/Settings.vue';

export default {
    name: 'CoursesDetail',
    metaInfo: {
        title: "Daftar Pembelajaran",
        titleTemplate: "%s - Elearning"
    },
    components: {
        lessons, member, settings
    },
    data() {
        return {
            detail: {},
            currentTabComponent: 'lessons'
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        changeTab: function(tabs) {
            this.currentTabComponent = tabs;
        },
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/detail';

            this.$http.get(uri, { params: {id: this.$route.params.id} }).then(res => {
                if (res.data.status)
                    this.detail = res.data.data;
            });
        },
    },
}
</script>