<template>
<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row justify-content-center">
            <div class="col-6">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Kelola Kurikulum</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'curriculum' }">Kurikulum</router-link>
                            </li>
                            <li class="breadcrumb-item active">Form Kurikulum</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex text-muted">
                            <div class="flex-shrink-0 me-3 align-self-center">
                                <div id="radialchart-1" class="apex-charts" dir="ltr"></div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <p class="mb-1">Silahkan lengkapi form dibawah ini</p>
                                <form class="mt-3" v-on:submit.prevent="onSubmit">
                                    <div class="form-group mb-3">
                                        <label class="form-label" for="code">Kode</label>
                                        <input class="form-control" type="text" id="name" v-model="form.code" placeholder="Masukan kode kurikulum">
                                    </div>

                                    <div class="form-group mb-3">
                                        <label class="form-label" for="name">Nama</label>
                                        <input class="form-control" :class="{ 'is-invalid': submitted && $v.form.name.$error }" type="text" id="name" v-model="form.name" placeholder="Masukan nama kurikulum">
                                        <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required!</div>
                                    </div>

                                    <div class="d-flex justify-content-end mt-4">
                                        <router-link :to="{name: 'curriculum'}" class="btn btn-light me-2"><i class="mdi mdi-arrow-left me-2"></i>Batal</router-link>
                                        <button type="submit" class="btn btn-primary"><i class="mdi mdi-content-save me-2"></i>Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

    </div>
    <!-- container-fluid -->
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";
export default {
    name: 'FormCurriculum',
    metaInfo: {
        title: "Form Curriculum",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            id: this.$route.params.id,
            submitted: false,
            waiting: false,
            form: {
                code: '',
                name: ''
            },
        }
    },
    validations: {
        form: {
            code: {
                required
            },
            name: {
                required
            },
        }
    },
    created() {
        this.fetchDetail();
    },
    methods: {
        createSlug: function (text) {
            return text.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '')
        },
        generateSlugCode() {
            this.form.code = this.createSlug(this.form.name)
        },
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/curriculum/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'curriculum'
                                    });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        fetchDetail: function() {
            if (typeof (this.id) !== 'undefined' && this.id !== null) {
                this.label  = "Update";

                let uri     = process.env.VUE_APP_APIHOST+'administrator/curriculum/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse = res.data.data;
                        this.form       = getResponse;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'curriculum' });
                            }
                        });
                    }
                });
            }
        },
    },
}
</script>
