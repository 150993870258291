<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card bg-primary">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center text-white">
                            <div class="d-block">
                                <div>
                                    Nilai KKM saat ini
                                </div>
                            </div>
                            <div class="d-flex text-center rounded border ps-2">
                                <div class="h4 text-white mb-0">{{$parent.detail.kkm || '-'}}</div>
                                <button type="button" class="btn btn-light btn-sm ms-2" data-bs-toggle="modal" data-bs-target="#modal-kkm">Setup KKM</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4 text-muted">Dibawah ini terdapat {{filterItems(dataList).length}} siswa.</h4>
                        <div class="row mb-3">
                            <div class="col-md-2 mb-2">
                                <select class="form-select">
                                    <option value="">Lihat Semua</option>
                                    <option value="unfinish">Belum Mengerjakan</option>
                                    <option value="progress">Sedang Mengerjakan</option>
                                    <option value="finish">Telah Mengerjakan</option>
                                </select>
                            </div>
                            <div class="col-md-8 mb-2">
                                <input type="text" class="form-control border-muted" placeholder="Cari member berdasarkan nomor induk atau nama disini ..." v-model="searchQuery">
                            </div>
                            <div class="col-auto">
                                <button type="button" class="btn" @click="deleteBatch()" :class="{'btn-secondary': selectedId.length == 0, 'btn-danger': selectedId.length > 0}" :disabled="selectedId.length == 0"><i class="mdi mdi-delete me-2"></i>Reset Terpilih</button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;" v-if="canResetQuiz">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" style="transform: scale(1.5);" id="customCheckall" v-model="checkAll" @change="selectAllData()" :checked="selectedId.length > 0">
                                                <label class="form-check-label" for="customCheckall"></label>
                                            </div>
                                        </th>
                                        <th width="10%">Nomor Induk</th>
                                        <th>Nama</th>
                                        <th width="10%" class="text-center">Waktu Pengerjaan</th>
                                        <th width="8%" class="text-center">Nilai</th>
                                        <th width="10%">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in filterItems(dataList)" :key="row.id">
                                        <td v-if="row.answer">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck1" style="transform: scale(1.5);" @change="selectData(row.id)" :checked="selectedId.indexOf(row.id) != -1">
                                                <label class="form-check-label" for="customCheck1"></label>
                                            </div>
                                        </td>
                                        <td width="15%">{{row.identity_number}}</td>
                                        <td>{{row.name}}</td>
                                        <td v-if="row.answer_id" class="text-dark font-size-16 text-center">{{row.uploaded | formatDate('MMM DD, YYYY hh:mm')}}</td>
                                        <td v-if="row.answer_id" class="text-dark font-size-16 text-center">
                                            <input type="number" class="form-control text-center" v-model="row.score" placeholder="Masukan nilai" />
                                        </td>
                                        <td v-if="row.answer_id" width="10%">
                                            <button type="button" class="btn btn-primary btn-sm me-2" @click="saveScore(row)">Simpan Nilai</button>
                                            <button type="button" class="btn btn-outline-primary btn-sm me-2" @click="detail(row)" data-bs-toggle="modal" data-bs-target="#modal-result" >Detail</button>
                                            <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteData(row)">Reset</button>
                                        </td>
                                        <td colspan="5" v-else>
                                            <div class="d-block rounded bg-light p-2 w-100 text-muted text-center">Belum Mengerjakan</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <div id="modal-result" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="d-block">
                            <h5 class="h5 text-muted" id="myModalLabel">Jawaban Siswa</h5>
                            <h5 class="modal-title" id="myModalLabel" v-if="resultDetail">{{resultDetail.identity_number}} | {{resultDetail.name}}</h5>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label>Deskripsi</label>
                            <div class="d-block bg-light p-3 text-muted mt-2" v-html="resultDetail.description"></div>
                        </div>
                        <div class="form-group">
                            <label>File Lampiran</label>
                            <div class="d-flex bg-light rounded p-3 mt-3" v-if="resultDetail.attachment"><i class="ri ri-search-eye-line"></i> <a :href="resultDetail.attachment" target="_blank" class="ms-2">Lihat File Sebelumnya</a></div>
                            <div class="d-block bg-light p-3 text-muted mt-2" v-else>Tidak ada lampiran</div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" ref="closeModal">Close</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <div id="modal-kkm" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="d-block">
                            <h5 class="h5 text-muted" id="myModalLabel">Setup Nilai KKM Tugas</h5>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label>Nilai KKM</label>
                            <input type="number" step="any" class="form-control" placeholder="Masukan nilai KKM" v-model="$parent.detail.kkm" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect" @click="saveScoreKKM">Simpan</button>
                        <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" ref="closeModal">Close</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
    </div>
    </template>
    <script>
    export default {
        name: 'ResultQuiz',
        metaInfo: {
            title: "Daftar Hasil Kuis",
            titleTemplate: "%s - Elearning"
        },
        data() {
            return {
                selectedId: [],
                checkAll: false,
                dataFetch: false,
                listResult: [],
                searchQuery: '',
                resultDetail: {},
                dataList: []
            }
        },
        computed: {
            canResetQuiz() {
                if (this.dataList.length > 0) {
                    return this.$arrayColumn(this.dataList, 'answer').filter((data) => {return data != null}).length > 0
                }
            }
        },
        created() {
            this.getData()
        },
        methods: {
            selectAllData: function () {
                if (this.checkAll) {
                    this.dataList.forEach(element => {
                        this.selectData(element.id)
                    });
                } else {
                    this.selectedId = []
                }
            },
            selectData: function (id) {
                if (this.selectedId.indexOf(id) != -1)
                    this.selectedId.splice(this.selectedId.indexOf(id), 1)
                else
                    this.selectedId.push(id)
            },
            getData: function () {
                let uri = process.env.VUE_APP_APIHOST + 'teacher/assignment/result';
    
                this.dataList = []
                this.dataFetch = true;
                this.$http.get(uri, {params: {
                    lessons_id: this.$route.params.id,
                    courses_id: this.$route.params.courses_id
                }}).then(res => {
                    this.dataFetch = false;
                    if (res.data.status)
                        this.dataList = res.data.data;
                });
            },
            deleteData: function (data) {
                this.$swal({
                    icon: 'info',
                    title: 'Apa apa kamu yakin ?',
                    text: 'Kamu akan mereset jawaban tugas dengan nama ' + data.name,
                    showCancelButton: true,
                    confirmButtonText: 'Hapus',
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/assignment/reset', {
                                id: data.answer_id,
                            })
                            .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.getData();
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                    }
                });
            },
            deleteBatch: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Apa apa kamu yakin ?',
                    text: 'Kamu akan menghapus ' + this.selectedId.length + ' data yang terpilih',
                    showCancelButton: true,
                    confirmButtonText: 'Hapus',
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/delete-member-batch', {
                                list_id: this.selectedId,
                            })
                            .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.selectedId=[];
                                            this.getData();
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                    }
                });
            },
            detail(data) {
                this.resultDetail = data
            },
            saveScore(data) {
                if (parseInt(data.score) < 0) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Nilai tidak bisa kurang dari 0',
                    });
                    data.score = 0
                } else {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/assignment/save/score', {
                        id: data.answer_id,
                        score: data.score,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    // this.$refs.closeModal.click()
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            },
            saveScoreKKM() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/lessons/save/score-kkm', {
                        id: this.$parent.detail.id,
                        kkm: this.$parent.detail.kkm
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                this.$refs.closeModal.click();
                                if (result.value) {
                                    this.$parent.getData();
                                }
                            });
                        } else {
                            this.$refs.closeModal.click();
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
            },
            filterItems: function (data) {
                var app = this;
                if (data.length > 0) {
                    return data.filter(function (result) {
                        let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                        return result.identity_number.toString().match(regex) || result.name.match(regex);
                    })
                } else {
                    return []
                }
            }
        },
    }
    </script>