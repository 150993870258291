<template>
<div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4 text-muted">Dibawah ini terdapat {{filterItems(dataList).length}} anggota yang terdaftar dikelas.</h4>
                    <div class="d-flex">
                        <div class="flex-grow-1 mb-4">
                            <input type="text" class="form-control border-muted" placeholder="Cari member berdasarkan nomor induk atau nama disini ..." v-model="searchQuery">
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-centered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Nomor Induk</th>
                                    <th>Nama</th>
                                    <th>Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in filterItems(dataList)" :key="row.id">
                                    <td width="15%">{{row.identity_number}}</td>
                                    <td>{{row.name}}</td>
                                    <td width="15%">
                                        <span class="badge bg-primary me-2" v-if="row.role == 'teacher'">Guru</span>
                                        <span class="badge bg-info me-2" v-if="row.role == 'student'">Siswa</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- end card-body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</div>
</template>
<script>
export default {
    name: 'Member',
    metaInfo: {
        title: "Daftar Anggota",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            selectedId: [],
            checkAll: false,
            dataFetch: false,
            searchQuery: '',
            dataList: []
        }
    },
    created() {
        this.getData()
    },
    methods: {
        selectAllData: function () {
            if (this.checkAll) {
                this.dataList.forEach(element => {
                    if (!element.is_coordinator)
                        this.selectData(element.member_id)
                });
            } else {
                this.selectedId = []
            }
        },
        selectData: function (id) {
            if (this.selectedId.indexOf(id) != -1)
                this.selectedId.splice(this.selectedId.indexOf(id), 1)
            else
                this.selectedId.push(id)
        },
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/list-member';

            this.dataFetch = true;
            this.$http.get(uri, {params: {courses_id: this.$route.params.id}}).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
            });
        },
        deleteData: function (data) {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus data dengan nama ' + data.name,
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/delete-member', {
                            id: data.member_id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteBatch: function () {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus ' + this.selectedId.length + ' data yang terpilih',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/delete-member-batch', {
                            list_id: this.selectedId,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.selectedId=[];
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            return data.filter(function (result) {
                let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                return result.identity_number.toString().match(regex) || result.name.match(regex);
            })
        }
    },
}
</script>