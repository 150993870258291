<template>
<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Kelola Data Grade Nilai</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <router-link :to="{name: 'dashboard'}">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item active">Kelola Grade Nilai</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <div class="card-title mb-4 text-muted">Filter</div>
                            <div>
                                <router-link :to="{name: 'add_grade'}" class="btn btn-primary"><i class="mdi mdi-plus me-2"></i>Tambah Data Baru</router-link>
                                <button type="button" class="btn ms-2" @click="deleteBatch()" :class="{'btn-secondary': selectedId.length == 0, 'btn-danger': selectedId.length > 0}" :disabled="selectedId.length == 0"><i class="mdi mdi-delete me-2"></i>Hapus Terpilih</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <label>Kata Kunci</label>
                                <input type="text" class="form-control border-muted" placeholder="Cari grade nilai disini ..." v-model="searchQuery">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4 text-muted">Dibawah ini terdapat {{filterItems(dataList).length}} indikator yang terdaftar didalam sistem.</h4>
                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" style="transform: scale(1.5);" id="customCheckall" v-model="checkAll" @change="selectAllData()">
                                                <label class="form-check-label" for="customCheckall"></label>
                                            </div>
                                        </th>
                                        <th>Skor Awal</th>
                                        <th>Skor Akhir</th>
                                        <th>Nilai</th>
                                        <th>Status</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in filterItems(dataList)" :key="row.id">
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck1" style="transform: scale(1.5);" @change="selectData(row.id)" :checked="selectedId.indexOf(row.id) != -1">
                                                <label class="form-check-label" for="customCheck1"></label>
                                            </div>
                                        </td>
                                        <td>{{row.start_score}}</td>
                                        <td>{{row.end_score}}</td>
                                        <td>{{row.grade}}</td>
                                        <td>
                                            <div v-if="row.status == '1'"><label class="badge bg-primary">Lulus</label></div>
                                            <div v-if="row.status == '0'"><label class="badge bg-warning">Tidak Lulus</label></div>
                                        </td>
                                        <td width="15%">
                                            <router-link :to="{name: 'edit_grade', params: {id: row.id}}" class="btn btn-outline-success btn-sm me-2">Edit</router-link>
                                            <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteData(row)">Hapus</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

    </div>
    <!-- container-fluid -->
</div>
</template>

<script>
export default {
    name: 'Grade',
    metaInfo: {
        title: "Grade",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            selectedId: [],
            checkAll: false,
            dataFetch: false,
            searchQuery: '',
            dataList: []
        }
    },
    component: {},
    created() {
        this.getData();
    },
    methods: {
        selectAllData: function () {
            if (this.checkAll) {
                this.dataList.forEach(element => {
                    this.selectData(element.id)
                });
            } else {
                this.selectedId = []
            }
        },
        selectData: function (id) {
            if (this.selectedId.indexOf(id) != -1)
                this.selectedId.splice(this.selectedId.indexOf(id), 1)
            else
                this.selectedId.push(id)
        },
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'administrator/grade/list';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
            });
        },
        deleteData: function (data) {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus data dengan nilai ' + data.grade,
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/grade/delete', {
                            id: data.id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteBatch: function () {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus ' + this.selectedId.length + ' data yang terpilih',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/grade/delete-batch', {
                            list_id: this.selectedId,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.selectedId=[];
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            return data.filter(function (result) {
                let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                return result.grade.match(regex);
            })
        }
    },
}
</script>
