<template>
    <div class="card">
        <div class="card-body">
            <div class="card-subtitle mt-0 mb-3">Please complete the form below.</div>
            <form v-on:submit.prevent="onSubmit">
                <div class="form-group mb-3">
                    <label for="identity_number">NIS</label>
                    <input type="number" class="form-control" id="identity_number" name="identity_number" autocomplete="off"
                        :class="{ 'is-invalid': submitted && $v.form.identity_number.$error }"
                        v-model="form.identity_number" placeholder="Masukan nis ...">
                    <div v-if="submitted && !$v.form.identity_number.required" class="invalid-feedback">NIS is required!
                    </div>
                    <div class="alert alert-info mt-2"><i class="mdi mdi-information me-2"></i>Mohon masukan NIS dengan
                        angka, ini akan digunakan sebagai username utama</div>
                </div>
                <div class="form-group mb-3">
                    <label for="nisn">NISN</label>
                    <input type="number" class="form-control" id="nisn" name="nisn" autocomplete="off"
                        :class="{ 'is-invalid': submitted && $v.form.nisn.$error }" v-model="form.nisn"
                        placeholder="Masukan nisn ...">
                    <div v-if="submitted && !$v.form.nisn.required" class="invalid-feedback">NISN is required!</div>
                </div>
                <div class="form-group mb-3">
                    <label for="name">Nama</label>
                    <input type="text" class="form-control" id="name" name="name"
                        :class="{ 'is-invalid': submitted && $v.form.name.$error }" v-model="form.name"
                        placeholder="Masukan nama ...">
                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Nama is required!</div>
                </div>
                <div class="form-group mb-3">
                    <label for="gender">Jenis Kelamin</label>
                    <select class="form-select border-muted w-25" id="gender" name="gender"
                        :class="{ 'is-invalid': submitted && $v.form.gender.$error }" v-model="form.gender">
                        <option value="male">Laki-laki</option>
                        <option value="female">Perempuan</option>
                    </select>
                    <div v-if="submitted && !$v.form.gender.required" class="invalid-feedback">Jenis Kelamin is required!
                    </div>
                </div>
                <div class="form-group row pt-0 mb-3">
                    <div class="col-md-6">
                        <label for="place_birth">Tempat Lahir</label>
                        <input type="text" class="form-control" id="place_birth" name="place_birth"
                            v-model="form.place_birth" placeholder="Masukan tempat lahir ...">
                    </div>
                    <div class="col-md-6">
                        <label for="date_birth">Tanggal lahir</label>
                        <date-picker v-model="form.date_birth" class="w-100" valueType="format"
                            placeholder="yyyy-mm-dd"></date-picker>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="email">Email</label>
                        <input type="text" class="form-control" id="email" name="email"
                            :class="{ 'is-invalid': submitted && $v.form.email.$error }" v-model="form.email"
                            placeholder="Enter email ...">
                        <div v-if="submitted && !$v.form.email.required" class="invalid-feedback">Email is required!</div>
                        <div class="alert alert-info mt-2"><i class="mdi mdi-information me-2"></i>Ini akan digunakan
                            sebagai akun kedua</div>
                    </div>
                    <div class="col-md-6">
                        <label for="religion">Agama</label>
                        <select class="form-select border-muted" id="religion" name="religion" v-model="form.religion">
                            <option value="">Pilih Agama</option>
                            <option value="islam">Islam</option>
                            <option value="hindu">Hindu</option>
                            <option value="buddha">Buddha</option>
                            <option value="kristen">Kristen</option>
                            <option value="katolik">Katholik</option>
                            <option value="lainnya">Lainnya</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-3">
                    <div class="col-md-6">
                        <label for="phone">Nomor Handphone</label>
                        <input type="text" class="form-control" id="phone" name="phone"
                            :class="{ 'is-invalid': submitted && $v.form.phone.$error }" v-model="form.phone"
                            placeholder="Masukan nomor handphone ...">
                        <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback">Nomor Handphone is
                            required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="postal_code">Kode Pos</label>
                        <input type="text" class="form-control" id="postal_code" name="postal_code"
                            v-model="form.postal_code" placeholder="Masukan kode pos ...">
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="address">Alamat</label>
                    <ckeditor :config="editorConfig" v-model="form.address" class="mt-2"></ckeditor>
                </div>
                <div class="d-flex justify-content-end">
                    <router-link :to="{ name: 'student' }" class="btn btn-light mt-2">Kembali</router-link>
                    <button type="submit" class="btn btn-primary ms-2 mt-2">Simpan & Lanjutkan</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'Profile',
    metaInfo: {
        title: 'Form Profile Siswa',
        titleTemplate: '%s - Unfari E-learning'
    },
    props: {
        form: Object
    },
    created() {
    },
    data() {
        return {
            submitted: false,
            waiting: false,
            editorConfig: {
                versionCheck: false,
                toolbar: [{
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                }, {
                    name: 'paragraph',
                    items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                }, {
                    name: 'links',
                    items: ['Link', 'Unlink']
                }, {
                    name: 'insert',
                    items: ['Image', 'EmbedSemantic', 'Table']
                }, {
                    name: 'tools',
                    items: ['Maximize']
                }],
                extraPlugins: 'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                imageUploadUrl: process.env.VUE_APP_APIHOST + 'uploader/images',
                uploadUrl: process.env.VUE_APP_APIHOST + 'uploader/files',
            }
        }
    },
    validations: {
        form: {
            identity_number: {
                required
            },
            nisn: {
                required
            },
            name: {
                required
            },
            gender: {
                required
            },
            phone: {
                required
            },
            email: {
                required
            },
        }
    },
    methods: {
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                this.$emit("listenerChild", this.form);
            }
        },
    }
}
</script>