<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <router-link class="btn btn-light me-3 btn-sm" :to="{name: 'manage_lessons', params: {'id': this.$route.params.courses_id}}"><i class="mdi mdi-chevron-left font-size-20 text-muted"></i></router-link>
                                </div>
                                <div class="d-block">
                                    <div class="font-size-16 text-dark">Form Materi</div>
                                    <div class="font-size-14 text-muted">Silahkan isi data dibawah ini dengan benar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex text-muted">
                                <div class="flex-shrink-0 me-3 align-self-center">
                                    <div id="radialchart-1" class="apex-charts" dir="ltr"></div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Silahkan lengkapi form dibawah ini</p>
                                    <form class="mt-3" v-on:submit.prevent="onSubmit">
                                        <div class="form-group mb-3">
                                            <label class="form-label" for="name">Nama</label>
                                            <input class="form-control" :class="{ 'is-invalid': submitted && $v.form.name.$error }" type="text" id="name" v-model="form.name" placeholder="Masukan nama pembelajaran">
                                            <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required!</div>
                                        </div>

                                        <div class="form-group mb-3">
                                            <label class="form-label" for="type">Tipe Pembelajaran</label>
                                            <select class="form-select border-muted" :class="{ 'is-invalid': submitted && $v.form.type.$error }" v-model="form.type" @change="onChangeType()">
                                                <option value="">Pilih Tipe Pembelajaran</option>
                                                <option value="LESSON">Materi</option>
                                                <option value="ASSIGNMENT">Tugas</option>
                                                <option value="QUIZ">Kuis</option>
                                                <option value="RESUME">Rangkuman</option>
                                                <option value="FINAL_EXAM">Ujian Akhir</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Tipe Pembelajaran is required!</div>
                                        </div>

                                        <div class="form-group mb-3" v-if="form.type != '' && form.type != 'QUIZ' && form.type != 'FINAL_EXAM'">
                                            <label class="form-label" for="name">Tipe Format Pembelajaran</label>
                                            <select class="form-select border-muted" :class="{ 'is-invalid': submitted && $v.form.format.$error }" v-model="form.format" @change="onchangeFormat()">
                                                <option value="">Pilih Format Pembelajaran</option>
                                                <option value="TEXT">Text</option>
                                                <option value="FILE">File</option>
                                                <option value="AUDIO">Audio</option>
                                                <option value="VIDEO">Video</option>
                                                <option value="YOUTUBE">Youtube</option>
                                                <option value="LINK">Link</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.format.required" class="invalid-feedback">Format is required!</div>
                                        </div>

                                        <div class="form-group mb-3" v-if="form.format == 'TEXT'">
                                            <label for="address">Deskripsi</label>
                                            <ckeditor :config="editorDescription" v-model="form.description" class="mt-2"></ckeditor>
                                        </div>
                                        
                                        <div class="form-group row mb-3" v-if="form.type == 'QUIZ' || form.type == 'FINAL_EXAM'">
                                            <div class="col-md-6">
                                                <label class="form-label">Acak Soal</label>
                                                <select class="form-select border-muted" v-model="form.is_random_question">
                                                    <option value="0">Tidak</option>
                                                    <option value="1">Iya</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label">Acak Jawaban</label>
                                                <select class="form-select border-muted" v-model="form.is_random_option">
                                                    <option value="0">Tidak</option>
                                                    <option value="1">Iya</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group mb-3" v-if="form.type == 'QUIZ' || form.type == 'FINAL_EXAM'">
                                            <label class="form-label" for="name">Durasi</label>
                                            <date-picker v-model="form.duration" class="w-100" valueType="format" type="time" :showSecond="false" format="hh:mm" placeholder="hh:mm"></date-picker>
                                        </div>

                                        <div class="mb-3" v-if="form.format == 'FILE' || form.format == 'AUDIO' || form.format == 'VIDEO'">
                                            <label class="form-label">Lampiran File</label>
                                            <input type="file" class="form-control" @change="getFile" ref="file" :accept="acceptFile">
                                            <a :href="form.attachment" v-if="form.id && form.attachment" class="d-block mt-2 p-2 bg-soft-info text-info font-weight-bold rounded" target="_blank"><i class="ri ri-search-eye-line me-2"></i>Lihat File Sebelumnya</a>
                                        </div>
                                        
                                        <div class="form-group mb-3" v-if="form.format == 'YOUTUBE'">
                                            <label class="form-label" for="references">URL Youtube</label>
                                            <input class="form-control" type="text" id="references" v-model="form.description" placeholder="Masukan link youtube">
                                        </div>
                                        
                                        <div class="form-group mb-3" v-if="form.format == 'LINK'">
                                            <label class="form-label" for="references">Link URL</label>
                                            <input class="form-control" type="text" id="references" v-model="form.description" placeholder="Masukan link url">
                                        </div>
                                        
                                        <div class="form-group mb-3">
                                            <label class="form-label" for="name">Catatan</label>
                                            <textarea class="form-control mt-1" rows="5" v-model="form.note" placeholder="Masukan catatan (opsional)"></textarea>
                                        </div>

                                        <div class="form-group mb-3" v-if="form.type == 'QUIZ' || form.type == 'FINAL_EXAM'">
                                            <label for="address">Pembahasan</label>
                                            <ckeditor :config="editorDiscussion" v-model="form.discussion" class="mt-2"></ckeditor>
                                        </div>
                                        
                                        <div class="form-group mb-3">
                                            <label class="form-label" for="references">Sumber Belajar</label>
                                            <input class="form-control" type="text" id="references" v-model="form.references" placeholder="Masukan sumber belajar">
                                        </div>

                                        <div class="form-group mb-3">
                                            <label class="form-label" for="status">Status</label>
                                            <select class="form-select border-muted" :class="{ 'is-invalid': submitted && $v.form.status.$error }" v-model="form.status">
                                                <option value="publish">Publish</option>
                                                <option value="unpublish">Unpublish</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.status.required" class="invalid-feedback">Status is required!</div>
                                        </div>

                                        <div class="form-group mb-3" v-if="form.type != ''">
                                            <label class="form-label" for="name">Tanggal Kedaluarsa</label>
                                            <select class="form-select border-muted" :class="{ 'is-invalid': submitted && $v.form.is_expired.$error }" v-model="form.is_expired">
                                                <option value="0">Tidak ada Kedaluarsa</option>
                                                <option value="1">Kedaluarsa</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.is_expired.required" class="invalid-feedback">Tanggal Kedaluarsa is required!</div>
                                        </div>
                                        <div class="form-group row mb-3 bg-light p-3 rounded-3 mx-0" v-if="form.is_expired == '1'">
                                            <div class="col-md-6">
                                                <label>Tanggal Mulai</label>
                                                <date-picker v-model="form.start_date" class="w-100" valueType="format" placeholder="yyyy-mm-dd"></date-picker>
                                            </div>
                                            <div class="col-md-6"> 
                                                <label>Tanggal Selesai</label>
                                                <date-picker v-model="form.end_date" class="w-100" valueType="format" placeholder="yyyy-mm-dd"></date-picker>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-end mt-4">
                                            <router-link :to="{name: 'manage_lessons', params: {'id': this.$route.params.courses_id}}" class="btn btn-light me-2"><i class="mdi mdi-arrow-left me-2"></i>Batal</router-link>
                                            <button type="submit" class="btn btn-primary"><i class="mdi mdi-content-save me-2"></i>Simpan</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    required
} from "vuelidate/lib/validators";
export default {
    name: 'FormLessons',
    metaInfo: {
        title: "Form Materi",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            form: {
                id: this.$route.params.id,
                sections_id: this.$route.params.section_id,
                name: '',
                description: '',
                status: 'unpublish',
                type: '',
                format: '',
                duration: '',
                references: '',
                note: '',
                is_expired: '0',
                attachment: '',
                start_date: '',
                end_date: '',
                is_random_option: '0',
                is_random_question: '0',
                discussion: ''
            },
            submitted: false,
            editorDescription: {
                versionCheck: false,
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ]
            },
            editorDiscussion: {
                versionCheck: false,
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ]
            },
            acceptFile: '*'
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            status: {
                required
            },
            type: {
                required
            },
            format: {
                required
            },
            is_expired: {
                required
            },
        }
    },
    created() {
        this.fetchDetail()
    },
    methods: {
        getFile(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.form.attachment = file;
            }
        },
        fetchDetail: function() {
            if (typeof (this.form.id) !== 'undefined' && this.form.id !== null) {
                this.label  = "Update";

                let uri     = process.env.VUE_APP_APIHOST+'teacher/lessons/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.form.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse     = res.data.data;
                        this.form           = getResponse;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'student' });
                            }
                        });
                    }
                });
            }
        },
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/lessons/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'manage_lessons',
                                        params: {id: this.$route.params.courses_id}
                                    });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        onchangeFormat: function() {
            if (this.form.format == 'FILE')
                this.acceptFile = '.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx'
            else if (this.form.format == 'AUDIO')
                this.acceptFile = '.mp3,.ogg'
            else if (this.form.format == 'VIDEO')
                this.acceptFile = '.mp4,.wov'
        },
        onChangeType: function() {
            if (this.form.type == 'QUIZ' || this.form.type == 'FINAL_EXAM')
                this.form.format = 'TEXT'
            else
                this.form.format = ''
        }
    },
}
</script>