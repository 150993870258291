<template>
<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Kelola Data Mata Pelajaran</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <router-link :to="{name: 'dashboard'}">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item active">Kelola Mata Pelajaran</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <div class="card-title mb-4 text-muted">Filter</div>
                            <div>
                                <router-link :to="{name: 'add_courses_category'}" class="btn btn-primary"><i class="mdi mdi-plus me-2"></i>Tambah Data Baru</router-link>
                                <button type="button" class="btn ms-2" @click="deleteBatch()" :class="{'btn-secondary': selectedId.length == 0, 'btn-danger': selectedId.length > 0}" :disabled="selectedId.length == 0"><i class="mdi mdi-delete me-2"></i>Hapus Terpilih</button>
                                <button type="button" class="btn ms-2 btn-white" @click="exportExcel()"><i class="mdi mdi-download me-2"></i>Export</button>
                                <button type="button" class="btn ms-2 btn-white" data-bs-toggle="modal" data-bs-target="#modal-import"><i class="mdi mdi-upload me-2"></i>Import</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <label>Kata Kunci</label>
                                <input type="text" class="form-control border-muted" placeholder="Cari mata pelajaran berdasarkan kode atau nama disini ..." v-model="searchQuery">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4 text-muted">Dibawah ini terdapat {{filterItems(dataList).length}} mata pelajaran yang terdaftar didalam sistem.</h4>
                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" style="transform: scale(1.5);" id="customCheckall" v-model="checkAll" @change="selectAllData()">
                                                <label class="form-check-label" for="customCheckall"></label>
                                            </div>
                                        </th>
                                        <th>Kode</th>
                                        <th>Nama</th>
                                        <th>Jurusan</th>
                                        <th>Kurikulum</th>
                                        <th>Pertemuan</th>
                                        <th>Status</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in filterItems(dataList)" :key="row.id">
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck1" style="transform: scale(1.5);" @change="selectData(row.id)" :checked="selectedId.indexOf(row.id) != -1">
                                                <label class="form-check-label" for="customCheck1"></label>
                                            </div>
                                        </td>
                                        <td>{{row.code}}</td>
                                        <td>{{row.name}}</td>
                                        <td>{{row.program_study}}</td>
                                        <td>{{row.curriculum}}</td>
                                        <td>{{row.sks}}</td>
                                        <td>
                                            <label class="badge bg-primary" v-if="row.status == 'publish'">Publish</label>
                                            <label class="badge bg-secondary" v-if="row.status != 'publish'">Unpublish</label>
                                        </td>
                                        <td width="15%">
                                            <router-link :to="{name: 'edit_courses_category', params: {id: row.id}}" class="btn btn-outline-success btn-sm me-2">Edit</router-link>
                                            <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteData(row)">Hapus</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

    </div>
    <!-- container-fluid -->
    <div id="modal-import" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-block">
                        <h5 class="modal-title" id="myModalLabel">Import Siswa</h5>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="password">Lampiran File</label>
                        <input type="file" class="form-control" @change="previewFiles" ref="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    </div>
                    <div class="mt-3 mb-2">Silahkan download format file excel dibawah ini.</div>
                    <a href="javascript:void(0)" @click="downloadFormat()">Download Format</a>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" ref="closeModalImport">Tutup</button>
                    <button type="button" class="btn btn-primary waves-effect waves-light" @click="importData()">Import</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div>
</div>
</template>

<script>
export default {
    name: 'CoursesCategory',
    metaInfo: {
        title: "CoursesCategory",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            selectedId: [],
            checkAll: false,
            dataFetch: false,
            submittedUpload: false,
            searchQuery: '',
            dataList: [],
            export: {
                file: null,
            }
        }
    },
    component: {},
    created() {
        this.getData();
    },
    methods: {
        selectAllData: function () {
            if (this.checkAll) {
                this.dataList.forEach(element => {
                    this.selectData(element.id)
                });
            } else {
                this.selectedId = []
            }
        },
        selectData: function (id) {
            if (this.selectedId.indexOf(id) != -1)
                this.selectedId.splice(this.selectedId.indexOf(id), 1)
            else
                this.selectedId.push(id)
        },
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'administrator/courses-category/list';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
            });
        },
        deleteData: function (data) {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus data dengan nama ' + data.name,
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/courses-category/delete', {
                            id: data.id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteBatch: function () {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus ' + this.selectedId.length + ' data yang terpilih',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/courses-category/delete-batch', {
                            list_id: this.selectedId,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.selectedId=[];
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            return data.filter(function (result) {
                let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                return result.code.match(regex) || result.name.match(regex);
            })
        },
        exportExcel: function() {
            let uri     = process.env.VUE_APP_APIHOST+'administrator/courses-category/export/excel';

            this.$http.get(uri,{
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                if(response.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Daftar Mata Pelajaran.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            });
        },
        importData: function() {
            this.submittedUpload = true;
            let formData = new FormData();
            formData.append('file', this.export.file);

            this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/courses-category/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.getData()
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.$refs.closeModalImport.click()
                    this.submittedUpload = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.submittedUpload = false;
                    return;
                });
        },
        downloadFormat: function() {
            let uri     = process.env.VUE_APP_APIHOST+'administrator/courses-category/format/excel';

            this.$http.get(uri,{
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                if(response.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Format Import Mata Pelajaran.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            });
        },
        previewFiles(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.export.file = file;
            }
        },
    },
}
</script>
