<template>
<div>
    <div id="layout-wrapper">
        <navbar></navbar>
        <sidebar></sidebar>
        <div class="main-content">
            <slot />
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Main",
    data() {
        return {

        }
    },
    created() {
    }
}
</script>
