<template>
    <div class="card">
        <div class="card-body">
            <div class="card-subtitle mt-0 mb-3">Please complete the form below.</div>
            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="study_program_id">Jurusan</label>
                        <select class="form-select border-muted" id="study_program_id" name="study_program_id" v-model="form.study_program_id">
                            <option value="">Pilih Jurusan</option>
                            <option v-for="item in list_program_study" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.study_program_id.required" class="invalid-feedback">Jurusan is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="education_id">Pendidikan</label>
                        <select class="form-select border-muted" id="education_id" name="education_id" v-model="form.education_id">
                            <option value="">Pilih Pendidikan</option>
                            <option v-for="item in list_education" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.education_id.required" class="invalid-feedback">Pendidikan is required!</div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="status">Status</label>
                        <select class="form-select border-muted" id="status" name="status" v-model="form.status">
                            <option value="tetap">Tetap</option>
                            <option value="tidak tetap">Tidak Tetap</option>
                        </select>
                        <div v-if="submitted && !$v.form.status.required" class="invalid-feedback">Status is required!</div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <router-link :to="{name: 'teacher'}" class="btn btn-light mt-2">Kembali</router-link>
                    <button type="submit" class="btn btn-primary ms-2 mt-2"><span v-if="!form.id">Simpan & Lanjutkan</span><span v-if="form.id">Simpan</span></button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Academic',
        metaInfo: {
            title: 'Form Akademik Guru',
            titleTemplate: '%s - Unfari E-learning'
        },
        props: {
            form: Object
        },
        created() {
            this.getStudyProgram();
            this.getEducation();
        },
        data() {
            return {
                list_program_study  : [],
                list_education      : [],
                submitted           : false,
                waiting             : false,
            }
        },
        validations: {
            form: {
                study_program_id: {
                    required
                },
                education_id: {
                    required
                },
                status: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the forms',
                    });

                    this.waiting = false;
                    return;
                } else {
                    this.$emit("listenerChild", this.form);
                }
            },
            getStudyProgram: function() {
                let uri     = process.env.VUE_APP_APIHOST+'program-study/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_program_study = res.data.data;
                    }
                });
            },
            getEducation: function() {
                let uri     = process.env.VUE_APP_APIHOST+'education/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_education = res.data.data;
                    }
                });
            },
        }
    }
</script>