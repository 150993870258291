<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4 text-muted">Menampilkan
                        {{ filterItems(dataList.list).length }} siswa.</h4>
                    <div class="table-responsive" v-if="filterItems(dataList.list).length > 0">
                        <table class="table table-centered table-bordered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th rowspan=2 class="text-center" style="width: 3%">No</th>
                                    <th rowspan=2 class="text-center" style="width: 15%">NIS</th>
                                    <th rowspan=2>Nama</th>
                                    <th colspan="2" class="text-center">Hasil CBT</th>
                                    <th rowspan=2 class="text-center">Nilai</th>
                                    <th rowspan=2 class="text-center">Catatan</th>
                                </tr>
                                <tr>
                                    <th class="text-center">Benar</th>
                                    <th class="text-center">Salah</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in filterItems(dataList.list)" :key="row.id">
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center" style="font-weight: bold;">{{ row.identity_number }}</td>
                                    <td>{{ row.name }}</td>
                                    <td class="text-center" style="font-weight: bold; font-size: 14pt;">{{ row.score.correct
                                    }}</td>
                                    <td class="text-center" style="font-weight: bold; font-size: 14pt;">{{ row.score.wrong +
                                        row.score.empty + row.score.wait
                                    }}</td>
                                    <td class="text-center" style="font-weight: bold; font-size: 14pt;">{{ row.score.score
                                    }}</td>
                                    <td class="text-center"
                                        :class="{ 'text-success': row.score.status == '1', 'text-muted': row.score.status != '1' }"
                                        style="font-weight: bold; font-size: 12pt;">{{ row.score.status
                                            == '1' ? 'Kompeten' : 'Tidak Kompeten'
                                        }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <template v-if="!dataFetch">
                        <div class="bg-light p-3 rounded text-center text-muted"
                            v-if="!params.courses_id || !params.quiz_id">
                            Silahkan untuk memilih <b>filter kelas, topik, dan kuis</b> untuk melihat analisis butir soal.
                        </div>
                        <div class="bg-light p-3 rounded text-center text-muted"
                            v-if="params.courses_id && params.quiz_id && filterItems(dataList.list).length == 0">
                            Data tidak ditemukan
                        </div>
                    </template>
                </div>
                <!-- end card-body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>
<script>
export default {
    name: 'AnalysisQuizList',
    props: {
        keyword: '',
        params: {
            type: Object,
            default: {
                courses_id: '',
                topic_id: '',
                quiz_id: '',
            }
        }
    },
    metaInfo: {
        title: "Laporan Analisis Butir",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            dataFetch: false,
            dataList: {
                question: [],
                list: []
            },
        }
    },
    component: {},
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            this.dataList = {
                question: [],
                list: []
            }
            let uri = process.env.VUE_APP_APIHOST + 'teacher/report/analysis-quiz';

            this.dataFetch = true;
            this.params.type = 'score'
            this.$http.get(uri, {
                params: this.params
            }).then(res => {
                this.dataFetch = false;
                if (res.data.status) {
                    this.dataList.question = res.data.data.question;
                    this.dataList.list = res.data.data.student;
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            if (data) {
                if (app.keyword) {
                    return data.filter(function (result) {
                        let regex = new RegExp('(' + app.keyword + ')', 'i');
                        return result.identity_number.toString().match(regex) || result.name.match(regex);
                    })
                } else {
                    return data;
                }
            } else {
                return []
            }
        },
    },
}
</script>