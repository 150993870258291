<template>
    <div class="page-content">
        <div class="container-fluid">
    
            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Dashboard</h4>
                    </div>
                </div>
            </div>
            <div class="alert alert-info" style="text-align: justify">
                Pembelajaran Bermakna (Meaningful Learning)
                yaitu proses pembelajaran yang dilakukan
                dengan cara menghubungkan informasi baru
                dengan pengetahuan yang telah kamu miliki,
                serta mengkaitkannya dengan pelajaran
                sehingga akan menghasilkan konsep-konsep
                baru.
            </div>
            <!-- end page title -->
    
            <div class="row">
                <div class="col-xl-3 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex text-muted">
                                <div class="flex-shrink-0  me-3 align-self-center">
                                    <div class="avatar-sm">
                                        <div class="avatar-title bg-light rounded-circle text-primary font-size-20">
                                            <i class="ri-airplay-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Jumlah Kelas</p>
                                    <h4>{{statistic.course}}</h4>
                                </div>
                            </div>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->
                </div>
                <div class="col-xl-3 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex text-muted">
                                <div class="flex-shrink-0  me-3 align-self-center">
                                    <div class="avatar-sm">
                                        <div class="avatar-title bg-light rounded-circle text-info font-size-20">
                                            <i class="ri-file-copy-2-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Jumlah Materi</p>
                                    <h4>{{statistic.material}}</h4>
                                </div>
                            </div>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->
                </div>
                <div class="col-xl-3 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex text-muted">
                                <div class="flex-shrink-0  me-3 align-self-center">
                                    <div class="avatar-sm">
                                        <div class="avatar-title bg-light rounded-circle text-warning font-size-20">
                                            <i class="ri-survey-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Jumlah Tugas</p>
                                    <h4>{{statistic.assignment}}</h4>
                                </div>
                            </div>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->
                </div>
                <div class="col-xl-3 col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex text-muted">
                                <div class="flex-shrink-0  me-3 align-self-center">
                                    <div class="avatar-sm">
                                        <div class="avatar-title bg-light rounded-circle text-danger font-size-20">
                                            <i class="ri-list-ordered"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Jumlah Kuis</p>
                                    <h4>{{statistic.quiz}}</h4>
                                </div>
                            </div>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Aktivitas Login</h4>
    
                            <div class="table-responsive">
                                <table class="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">IP Address</th>
                                            <th scope="col">Nama User</th>
                                            <th scope="col">Metode</th>
                                            <th scope="col">Device</th>
                                            <th scope="col">Platform</th>
                                            <th scope="col">Waktu</th>
                                            <th scope="col" colspan=2>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in statistic.log_activity" :key="row.id">
                                            <td>{{row.ip_address}}</td>
                                            <td>{{row.name}}</td>
                                            <td>{{row.method}}</td>
                                            <td>{{row.device    }}</td>
                                            <td>
                                                <div class="fw-bold">
                                                    {{row.platform}}
                                                </div>
                                                <div>
                                                    {{row.browser}} ({{row.version}})
                                                </div>
                                            </td>
                                            <td>{{row.time | formatDate('DD/MM/YYYY hh:mm')}}</td>
                                            <td>
                                                <div v-if="row.status == 'success'"><i class="mdi mdi-checkbox-blank-circle text-success me-1"></i> Login Berhasil</div>
                                                <div v-if="row.status == 'wrong_password'"><i class="mdi mdi-checkbox-blank-circle text-danger me-1"></i> Password Salah</div>
                                                <div v-if="row.status == 'critical_failure'"><i class="mdi mdi-checkbox-blank-circle text-danger me-1"></i> Kegagalan Parah</div>
                                            </td>
                                            <td>
                                                <button class="btn btn-sm btn-outline-primary" @click="detailLog(row)">Lihat Detail</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
    
        </div>
        <!-- container-fluid -->
        <modal name="detail" :adaptive="true" :height="'auto'">
            <div class="d-block p-3">
                <div class="d-block mb-3">
                    <div class="card-title m-3">
                        Detail Log Aktivitas
                    </div>
                    <div class="card-body mt-3" v-if="dataDetailLog">
                        <div class="d-flex justify-content-between bg-light rounded p-2 mb-2">
                            <div><span v-if="dataDetailLog.role == 'teacher'">NIDN</span><span v-else>NIS</span></div>
                            <div class="shrink-0" style="font-weight: bold;"><span v-if="dataDetailLog.role == 'teacher'">{{ dataDetailLog.nidn }}</span><span v-else>{{ dataDetailLog.identity_number }}</span></div>
                        </div>
                        <div class="d-flex justify-content-between bg-light rounded p-2 mb-2">
                            <div>Nama</div>
                            <div class="shrink-0" style="font-weight: bold;">{{dataDetailLog.name}}</div>
                        </div>
                        <div class="d-flex justify-content-between bg-light rounded p-2 mb-2">
                            <div>Email</div>
                            <div class="shrink-0" style="font-weight: bold;">{{dataDetailLog.email}}</div>
                        </div>
                        <div class="d-flex justify-content-between bg-light rounded p-2 mb-2">
                            <div>Telepon</div>
                            <div class="shrink-0" style="font-weight: bold;">{{dataDetailLog.phone}}</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end m-3">
                    <button class="btn btn-light" @click="closeModal()">Tutup</button>
                </div>
            </div>
        </modal>
    </div>
    </template>
    
    <script>
    export default {
        name: 'Dashboard',
        metaInfo: {
            title: "Dashboard",
            titleTemplate: "%s - Elearning"
        },
        data() {
            return {
                dataDetailLog: {},
                statistic: {
                    material: 0,
                    assignment: 0,
                    quiz: 0,
                    course: 0,
                    log_activity: []
                }
            }
        },
        component: {},
        created() {
            this.getData();
        },
        methods: {
            getData: function () {
                let uri = process.env.VUE_APP_APIHOST + 'teacher/dashboard';
    
                this.dataFetch = true;
                this.$http.get(uri).then(res => {
                    this.dataFetch = false;
                    this.statistic = res.data.data;
                });
            },
            detailLog(data) {
                let uri = process.env.VUE_APP_APIHOST + 'user/detail?id=' + data.user_id;

                this.dataFetch = true;
                this.$http.get(uri).then(res => {
                    this.dataFetch = false;
                    this.dataDetailLog = res.data.data;
                    this.$modal.show('detail')
                });
            },
            closeModal: function() {
                this.$modal.hide('detail')
            },
        },
    }
    </script>
    