<template>
    <div class="row">
        <div class="col-md-12">
            <div class="d-grid mb-3">
                <button class="btn btn-primary font-size-16 p-3 waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#modal-form-section" @click="openFormTopic()"><i class="mdi mdi-plus me-2"></i>Tambah Topik Baru</button>
            </div>
            <div id="accordion" v-if="dataList.length > 0">
                <draggable v-model="dataList" group="dataList" @end="updateTopic">
                    <transition-group>
                        <div class="card" v-for="row in dataList" :key="row.id">
                            <div class="card-header bg-white p-3 border-bottom" id="headingOne">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="d-flex align-items-center">
                                            <i class="mdi mdi-drag font-size-20 me-2 text-muted" style="cursor: move"></i>
                                            <a data-bs-toggle="collapse" data-parent="#accordion" :href="'#collapseOne' + row.id" aria-expanded="true" aria-controls="collapseOne" class="text-dark">
                                                <h5 class="font-size-16 ms-2">{{row.section}}</h5>
                                                <h5 class="mb-0 ms-2 font-size-14 text-muted">{{row.child.length}} Materi</h5>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-2 text-end">
                                        <button class="btn btn-primary me-2 btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#modal-form-section" @click="openFormTopic(row)"><i class="mdi mdi-pencil-outline font-size-18"></i></button>
                                        <button class="btn btn-light btn-sm" type="button"><i class="mdi mdi-trash-can-outline font-size-18 text-muted" @click="deleteDataTopic(row)"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div :id="'collapseOne' + row.id" class="collapse" aria-labelledby="headingOne" data-parent="#accordion" :style="{'min-height': row.child.length == 1 ? '200px' : ''}">
                                <div class="card-body">
                                    <div class="mx-4 mb-3" v-if="row.child.length > 0">
                                        <draggable v-model="row.child" group="child" :key="`sub-${row.id}`" @end="updateLesson">
                                            <transition-group>
                                                <a href="#" class="text-body d-block" v-for="(lesson, index) in row.child" :class="{'border-bottom': index < (row.child.length-1)}" :key="lesson.id">
                                                    <div class="d-flex py-3 align-items-center">
                                                        <div class="flex-shrink-0 me-3 align-self-start">
                                                            <div class="avatar-sm">
                                                                <span class="avatar-title bg-soft-primary rounded-circle text-primary">
                                                                    <i class="ri-list-check-2 font-size-20" v-if="lesson.type == 'QUIZ' || lesson.type == 'FINAL_EXAM'"></i>
                                                                    <i class="ri-todo-line font-size-20" v-if="lesson.type == 'ASSIGNMENT'"></i>
                                                                    <i class="ri-file-list-3-line font-size-20" v-if="lesson.type == 'RESUME'"></i>

                                                                    <i class="ri-file-list-3-line font-size-20" v-if="lesson.type == 'LESSON' && lesson.format == 'TEXT'"></i>
                                                                    <i class="ri-sticky-note-line font-size-20" v-if="lesson.type == 'LESSON' && lesson.format == 'FILE'"></i>
                                                                    <i class="ri-music-line font-size-20" v-if="lesson.type == 'LESSON' && lesson.format == 'AUDIO'"></i>
                                                                    <i class="ri-live-line font-size-20" v-if="lesson.type == 'LESSON' && (lesson.format == 'VIDEO' || lesson.format == 'YOUTUBE')"></i>
                                                                    <i class="ri-links-line font-size-20" v-if="lesson.format == 'LINK'"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 overflow-hidden">
                                                            <h5 class="font-size-14 mb-1">{{lesson.name}}</h5>
                                                            <p class="text-truncate mb-0">
                                                                <!-- Tanggal dibuat : Apr 22, 2022, 11:03:03 AM -->
                                                                Tanggal dibuat : {{lesson.created_at | formatDate('MMM DD, YYYY HH:mm')}}
                                                            </p>
                                                            <div class="d-flex mt-2" v-if="lesson.is_expired == '1'">
                                                                <div class="p-2 rounded bg-light w-25">
                                                                    <div class="d-flex text-muted">
                                                                        <i class="ri ri-calendar-line me-2"></i>
                                                                        <div class="d-block">
                                                                            <div>
                                                                                Tanggal Mulai
                                                                            </div>
                                                                            <div class="font-size-15 text-dark font-weight-400">
                                                                                {{lesson.start_date | formatDate('MMM DD, YYYY')}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-2 rounded bg-light w-25 ms-2">
                                                                    <div class="d-flex text-muted">
                                                                        <i class="ri ri-calendar-line me-2"></i>
                                                                        <div class="d-block">
                                                                            <div>
                                                                                Tanggal Selesai
                                                                            </div>
                                                                            <div class="font-size-15 text-dark font-weight-400">
                                                                                {{lesson.end_date | formatDate('MMM DD, YYYY')}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-shrink-0 font-size-16 me-3">
                                                            <span class="badge bg-primary me-2" v-if="lesson.status == 'publish'">PUBLISH</span>
                                                            <span class="badge bg-secondary me-2" v-if="lesson.status == 'unpublish'">UNPUBLISH</span>
                                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'LESSON'">MATERI</span>
                                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'ASSIGNMENT'">TUGAS</span>
                                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'QUIZ'">QUIZ</span>
                                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'FINAL_EXAM'">UJIAN AKHIR</span>
                                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'RESUME'">RANGKUMAN</span>
                                                            <span class="badge bg-light ms-2 text-uppercase">{{lesson.format}}</span>
                                                        </div>
                                                        <div class="flex-shrink-0 font-size-16 ms-4">
                                                            <div class="dropdown">
                                                                <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i class="mdi mdi-dots-vertical"></i>
                                                                </button>
                                                                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(0px, 40px);" data-popper-placement="bottom-start">
                                                                    <a class="dropdown-item" href="#" v-if="lesson.type == 'QUIZ' || lesson.type == 'FINAL_EXAM'">
                                                                        <div class="d-grid">
                                                                            <router-link class="btn btn-primary" :to="{name: 'manage_quiz', params: {courses_id: params.id, id: lesson.id}}">Kelola Kuis</router-link>
                                                                        </div>
                                                                    </a>
                                                                    <a class="dropdown-item" href="#" v-if="lesson.type == 'ASSIGNMENT'">
                                                                        <div class="d-grid">
                                                                            <router-link :to="{'name': 'manage_assignment', params: {courses_id: params.id, id: lesson.id}}" class="btn btn-primary">Kelola Tugas</router-link>
                                                                        </div>
                                                                    </a>
                                                                    <a class="dropdown-item mt-2" :href="lesson.attachment" v-if="lesson.format == 'FILE' && lesson.attachment" download>Download File</a>
                                                                    <router-link class="dropdown-item" v-if="lesson.type == 'LESSON' || lesson.type == 'RESUME'" :to="{name: 'detail_lessons', params: {courses_id: params.id, id: lesson.id}}"><span v-if="lesson.type == 'RESUME'">Lihat Rangkuman</span><span v-else>Lihat Materi</span></router-link>
                                                                    <div class="dropdown-divider"></div>
                                                                    <router-link class="dropdown-item" :to="{name: 'edit_lessons', params: {courses_id: params.id, section_id: row.id, id: lesson.id}}">Edit</router-link>
                                                                    <a class="dropdown-item" href="#" @click="deleteData(lesson)">Hapus</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </transition-group>
                                        </draggable>
                                    </div>
                                    <div class="mx-4">
                                        <div class="d-block p-3 bg-light text-center mb-3 rounded-3 text-muted" v-if="row.child.length == 0">Belum ada materi yang dibuat.</div>
                                        <div class="d-grid"><router-link :to="{name: 'add_lessons', params: {courses_id: params.id, section_id: row.id}}" class="btn btn-outline-primary p-3 font-size-16"><i class="mdi mdi-plus me-2"></i>Tambah Materi Baru</router-link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </draggable>
            </div>
            <div v-if="dataList.length == 0" class="d-block p-3 text-muted text-center">
                Kamu belum memiliki pembelajaran saat ini.
            </div>

            <div id="modal-form-section" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myModalLabel">{{form.title}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Masukan nama topik" v-model="form.name">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" ref="closeModal">Close</button>
                            <button type="button" class="btn btn-primary waves-effect waves-light" @click="save()">Save</button>
                        </div>
                    </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
            </div>
        </div>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import lodash from 'lodash'
export default {
    name: 'Lessons',
    components: {
        draggable,
    },
    metaInfo: {
        title: "Daftar Pembelajaran",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            dataFetch: false,
            dataList: [],
            tmpDataList: [],
            params: {
                id: this.$route.params.id
            },
            form: {
                id: '',
                title: 'Tambah Topik Baru',
                name: '',
                courses_id:  this.$route.params.id
            }
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/lessons/list';

            this.dataList = [];
            this.dataFetch = true;
            this.$http.get(uri, { params: this.params }).then(res => {
                this.dataFetch = false;
                if (res.data.status) {
                    this.dataList = res.data.data;
                    this.tmpDataList = JSON.parse(JSON.stringify(res.data.data));
                }
            });
        },
        openFormTopic: function (data=null) {
            if (data) {
                this.form.id = data.id
                this.form.title = 'Ubah Topik'
                this.form.name = data.section
            } else {
                this.form.id = ''
                this.form.title = 'Tambah Topik'
                this.form.name = ''
            }
        },
        save: function() {
            let formData = new FormData();

            for (var index in this.form) {
                formData.append(index, this.form[index]);
            }

            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/lessons/save-topic', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        });
                        this.$refs.closeModal.click();
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
        },
        deleteData: function (data) {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus data dengan nama ' + data.name,
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/lessons/delete', {
                            id: data.id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteDataTopic: function (data) {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus data dengan nama ' + data.section,
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/lessons/delete-topic', {
                            id: data.id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        updateTopic() {
            const dataTopicIdOri = lodash.map(this.tmpDataList, 'id')
            const dataTopicId = lodash.map(this.dataList, 'id')
            if (!lodash.isEqual(dataTopicId, dataTopicIdOri)) {
                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/lessons/update-sort-number-topic', {
                        ...dataTopicId,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                            });
                            this.tmpDataList = JSON.parse(JSON.stringify(this.dataList))
                        } else {
                            this.dataList = JSON.parse(JSON.stringify(this.tmpDataList))
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    }). catch((error) => {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: error.message,
                        });
                        this.dataList = JSON.parse(JSON.stringify(this.tmpDataList))
                    })
            }
        },
        updateLesson(event) {
            // mapping list lesson id original
            let tmpLessonIdOri = []
            const listAllLessonOri = lodash.map(this.tmpDataList, 'child')

            listAllLessonOri.forEach(data => {
                tmpLessonIdOri.push(...lodash.map(data, 'id'))
            });

            // mapping list lesson id from sortable
            let tmpLessonId = []
            const listAllLesson = lodash.map(this.dataList, 'child')

            listAllLesson.forEach(element => {
                tmpLessonId.push(...lodash.map(element, 'id'))
            });

            if (!lodash.isEqual(tmpLessonId, tmpLessonIdOri)) {
                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/lessons/update-sort-number-lesson', {
                        ...tmpLessonId,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.tmpDataList = JSON.parse(JSON.stringify(this.dataList))
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                            });
                        } else {
                            this.dataList = JSON.parse(JSON.stringify(this.tmpDataList))
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    }). catch((error) => {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: error.message,
                        });
                        this.dataList = JSON.parse(JSON.stringify(this.tmpDataList))
                    })
            }
        }
    },
}
</script>