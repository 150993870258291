<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Kelola Data Siswa</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item active">Kelola Siswa</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div class="card-title mb-4 text-muted">Filter</div>
                                <div>
                                    <button type="button" class="btn ms-2 btn-white" @click="exportExcel()"><i
                                            class="mdi mdi-download me-2"></i>Export</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 mb-2">
                                    <label>Jurusan</label>
                                    <select class="form-select border-muted" v-model="params.program_study_id"
                                        @change="getData()">
                                        <option value="">Semua Jurusan</option>
                                        <option v-for="row in list_program_study" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-2 mb-2">
                                    <label>Jenjang</label>
                                    <select class="form-select border-muted" v-model="params.education_id"
                                        @change="getData()">
                                        <option value="">Semua Jenjang</option>
                                        <option v-for="row in list_education" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-2 mb-2">
                                    <label>Kelas</label>
                                    <select class="form-select border-muted" v-model="params.courses_id"
                                        @change="getData()">
                                        <option value="">Semua Kelas</option>
                                        <option v-for="row in list_courses" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-5 mb-2">
                                    <label>Kata Kunci</label>
                                    <input type="text" class="form-control border-muted"
                                        placeholder="Cari siswa berdasarkan nis atau nama disini ..." v-model="searchQuery">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-4 text-muted">Dibawah ini terdapat
                                {{ filterItems(dataList.list).length }} siswa yang terdaftar didalam sistem.</h4>
                            <div class="table-responsive" v-if="filterItems(dataList.list).length > 0">
                                <table class="table table-centered table-bordered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th rowspan=2 class="text-center" style="width: 3%">No</th>
                                            <th rowspan=2 class="text-center" style="width: 15%">NISN</th>
                                            <th rowspan=2 class="text-center">Nama</th>
                                            <th :colspan="dataList.topic.length" class="text-center">Nilai Hasil Belajar
                                            </th>
                                            <th rowspan=2 class="text-center">Keterangan</th>
                                        </tr>
                                        <tr v-if="dataList.topic.length > 0">
                                            <th class="text-center" style="width: 10%" v-for="col in dataList.topic">
                                                {{ col.name }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, index) in filterItems(dataList.list)" :key="row.id">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td>{{ row.nisn }}</td>
                                            <td>{{ row.name }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="bg-light p-3 rounded text-center text-muted"
                                v-if="filterItems(dataList.list).length == 0">
                                Data tidak ditemukan
                            </div>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'FinalExam',
    metaInfo: {
        title: "Laporan Hasil Ujian Akhir",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            dataFetch: false,
            searchQuery: '',
            dataList: [],
            list_program_study: [],
            list_program: [],
            list_education: [],
            list_courses: [],
            params: {
                program_id: '',
                program_study_id: '',
                education_id: '',
                courses_id: ''
            },
        }
    },
    component: {},
    created() {
        this.getData();
        this.getStudyProgram();
        this.getProgram();
        this.getEducation();
        this.getCourses();
    },
    methods: {
        getData: function () {
            this.dataList = []
            let uri = process.env.VUE_APP_APIHOST + 'teacher/report/final-exam';

            this.dataFetch = true;
            this.$http.get(uri, { params: this.params }).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
            });
        },
        filterItems: function (data) {
            var app = this;
            if (data) {
                return data.filter(function (result) {
                    let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                    return result.identity_number.toString().match(regex) || result.name.match(regex);
                })
            } else {
                return []
            }
        },
        getStudyProgram: function () {
            let uri = process.env.VUE_APP_APIHOST + 'program-study/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_program_study = res.data.data;
                }
            });
        },
        getProgram: function () {
            let uri = process.env.VUE_APP_APIHOST + 'program/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_program = res.data.data;
                }
            });
        },
        getEducation: function () {
            let uri = process.env.VUE_APP_APIHOST + 'education/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_education = res.data.data;
                }
            });
        },
        getCourses: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_courses = res.data.data;
                }
            });
        },
    },
}
</script>
    