<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-block">
                        <div class="mb-4 mt-2 rounded bg-soft-info text-info p-2 d-flex align-items-center"><i class="ri ri-information-line me-2 font-size-16"></i>Catatan : <div class="ms-2" v-html="detail.note"></div></div>
                        <div class="mt-3" v-html="detail.description" v-if="detail.format == 'TEXT'"></div>
                        <iframe :src="detail.attachment" v-if="detail.format == 'FILE'" width="100%" height="1000"></iframe>
                        <div v-if="detail.format == 'AUDIO'" class="col-md-12">
                            <vue-plyr>
                            <div class="plyr__video-embed">
                                <iframe
                                :src="detail.attachment"
                                width="100%"
                                height="500"
                                
                                allowfullscreen
                                allowtransparency
                                allow="autoplay"
                                ></iframe>
                            </div>
                            </vue-plyr>
                        </div>
                        <div v-if="detail.format == 'YOUTUBE'" class="col-md-12">
                            <vue-plyr>
                                <div class="plyr__video-embed">
                                    <iframe
                                    :src="detail.description"
                                    allowfullscreen
                                    allowtransparency
                                    allow="autoplay"
                                    ></iframe>
                                </div>
                            </vue-plyr>
                        </div>                                    
                        <div v-if="detail.format == 'VIDEO'" class="col-md-12">
                            <vue-plyr>
                                <video
                                    controls
                                    crossorigin
                                    playsinline>
                                    <source
                                        size="1080"
                                        :src="detail.attachment"
                                        type="video/mp4"
                                    />
                                    </video>
                            </vue-plyr>
                        </div>
                        <a v-if="detail.format == 'LINK'" :href="detail.description" class="d-flex align-items-center alert alert-info w-100" target="_blank" style="font-weight: bold;"><i class="ri ri-links-line me-2"></i> Kunjungi Link URL</a>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex"><i class="ri ri-links-line me-2"></i>Referensi : <a :href="detail.references" class="text-primary ms-2" target="_blank">{{detail.references}}</a></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Question',
    metaInfo: {
        title: "Kelola Soal",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            detail: {}
        }
    },
    mounted() {
        this.fetchDetail()
    },
    methods: {
        fetchDetail: function() {
            let uri     = process.env.VUE_APP_APIHOST+'teacher/lessons/detail';

            this.dataFetch  = true;                
            this.$http.get(uri,{
                params : {
                    id : this.$route.params.id
                }
            }).then(res => {
                if(res.data.status) {
                    let getResponse = res.data.data;
                    this.detail       = getResponse;
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push({ name: 'manage_lessons', params: {id: this.$route.params.courses_id} });
                        }
                    });
                }
            });
        },
    },
}
</script>