<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4 text-muted">Dibawah ini terdapat {{filterItems(dataList).length}} siswa.</h4>
                        <div class="row mb-3">
                            <div class="col-md-2 mb-2">
                                <select class="form-select">
                                    <option value="">Lihat Semua</option>
                                    <option value="unfinish">Belum Mengerjakan</option>
                                    <option value="progress">Sedang Mengerjakan</option>
                                    <option value="finish">Telah Mengerjakan</option>
                                </select>
                            </div>
                            <div class="col-md-8 mb-2">
                                <input type="text" class="form-control border-muted" placeholder="Cari member berdasarkan nomor induk atau nama disini ..." v-model="searchQuery">
                            </div>
                            <div class="col-auto">
                                <button type="button" class="btn" @click="deleteBatch()" :class="{'btn-secondary': selectedId.length == 0, 'btn-danger': selectedId.length > 0}" :disabled="selectedId.length == 0"><i class="mdi mdi-delete me-2"></i>Reset Terpilih</button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;" v-if="canResetQuiz">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" style="transform: scale(1.5);" id="customCheckall" v-model="checkAll" @change="selectAllData()" :checked="selectedId.length > 0">
                                                <label class="form-check-label" for="customCheckall"></label>
                                            </div>
                                        </th>
                                        <th width="10%">Nomor Induk</th>
                                        <th>Nama</th>
                                        <th width="10%" class="text-center">Waktu Pengerjaan</th>
                                        <th width="10%" class="text-center">Benar</th>
                                        <th width="10%" class="text-center">Salah</th>
                                        <th width="10%" class="text-center">Belum Dinilai</th>
                                        <th width="10%" class="text-center">Nilai</th>
                                        <th width="10%">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in filterItems(dataList)" :key="row.id">
                                        <td v-if="row.answer">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck1" style="transform: scale(1.5);" @change="selectData(row.id)" :checked="selectedId.indexOf(row.id) != -1">
                                                <label class="form-check-label" for="customCheck1"></label>
                                            </div>
                                        </td>
                                        <td width="15%">{{row.identity_number}}</td>
                                        <td>{{row.name}}</td>
                                        <td v-if="row.answer_id" class="text-dark font-size-16 text-center">{{row.start_date | formatDate('MMM DD, YYYY hh:mm')}}</td>
                                        <td v-if="row.answer_id" class="text-dark font-size-16 text-center">{{row.answer.correct}}</td>
                                        <td v-if="row.answer_id" class="text-dark font-size-16 text-center">{{row.answer.wrong}}</td>
                                        <td v-if="row.answer_id" class="text-dark font-size-16 text-center">{{row.answer.wait}}</td>
                                        <td v-if="row.answer_id" class="text-dark font-size-16 text-center">{{row.answer.score}}</td>
                                        <td v-if="row.answer_id" width="10%">
                                            <button type="button" class="btn btn-primary btn-sm me-2" @click="detail(row)" data-bs-toggle="modal" data-bs-target="#modal-result" >Detail</button>
                                            <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteData(row)">Reset</button>
                                        </td>
                                        <td colspan="5" v-else>
                                            <div class="d-block rounded bg-light p-2 w-100 text-muted text-center">Belum Mengerjakan</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <div id="modal-result" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="d-block">
                            <h5 class="h5 text-muted" id="myModalLabel">Daftar Jawaban Siswa</h5>
                            <h5 class="modal-title" id="myModalLabel" v-if="resultDetail.student">{{resultDetail.student.identity_number}} | {{resultDetail.student.name}}</h5>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mx-0 mb-3 rounded bg-light py-3" v-if="resultDetail.list.length > 0" v-for="(row, pos) in resultDetail.list">
                            <div class="col-md-9">
                                <div class="d-block bg-white rounded p-3">
                                    <div class="d-flex align-items-center">
                                        <button type="button" class="btn btn-white btn-square font-size-16 me-3">{{pos + 1}}</button>
                                        <div class="d-block" v-html="row.question"></div>
                                    </div>
                                    <hr>
                                    <div class="row justify-content-between">
                                        <div class="col-md-6">
                                            <label>Jawaban Benar</label>
                                            <div class="d-block rounded bg-light p-3">
                                                <label class="p-0 m-0" v-if="row.answer" v-html="row.answer"></label>
                                                <div class="d-flex p-0 m-0 text-muted" v-else><i class="ri ri-error-warning-line me-2"></i>Belum disetting</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Jawaban Siswa</label>
                                            <div class="d-block rounded bg-light p-3">
                                                <label class="p-0 m-0" v-if="row.student_answer" v-html="row.student_answer"></label>
                                                <div class="d-flex p-0 m-0 text-muted" v-else><i class="ri ri-error-warning-line me-2"></i>Belum disetting</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-block bg-white rounded p-3">
                                    <div class="d-flex align-items-center h5"><i class="ri ri-pencil-ruler-2-fill m-1"></i> <span>Penilaian</span></div>
                                    <span class="badge badge-soft-primary font-size-14 my-1 me-1">Point {{row.point}}</span>
                                    <span class="badge badge-soft-info font-size-14 my-1 mx-1" v-if="row.type == 'MULTIPLE_CHOICE'">Pilihan Ganda</span>
                                    <span class="badge badge-soft-info font-size-14 my-1 mx-1" v-if="row.type == 'ESSAY'">Esai</span>
                                    <hr>
                                    <div class="form-group" v-if="row.type == 'MULTIPLE_CHOICE'">
                                        <label>Status Jawaban</label>
                                        <select class="form-select border-muted" v-model="row.status">
                                            <option value="correct">Benar</option>
                                            <option value="wrong">Salah</option>
                                        </select>
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Nilai</label>
                                        <input class="form-control" type="number" :max="row.point" min="0" placeholder="Masukan nilai" v-model="row.score">
                                    </div>
                                    <div class="d-flex alert alert-warning mt-2" v-if="row.status == 'wait'"><i class="ri ri-error-warning-line me-1"></i><span>Belum dinilai</span></div>
                                    <div class="d-grid mt-3">
                                        <button type="button" class="btn btn-primary" @click="saveScore(row)">Simpan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-block bg-light p-3 text-center text-muted mt-3" v-else>
                            Tidak ada data
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" ref="closeModal">Close</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
    </div>
    </template>
    <script>
    export default {
        name: 'ResultQuiz',
        metaInfo: {
            title: "Daftar Hasil Kuis",
            titleTemplate: "%s - Elearning"
        },
        data() {
            return {
                selectedId: [],
                checkAll: false,
                dataFetch: false,
                listResult: [],
                searchQuery: '',
                resultDetail: {
                    list: []
                },
                dataList: []
            }
        },
        computed: {
            canResetQuiz() {
                if (this.dataList.length > 0) {
                    return this.$arrayColumn(this.dataList, 'answer').filter((data) => {return data != null}).length > 0
                }
            }
        },
        created() {
            this.getData()
        },
        methods: {
            selectAllData: function () {
                if (this.checkAll) {
                    this.dataList.forEach(element => {
                        this.selectData(element.id)
                    });
                } else {
                    this.selectedId = []
                }
            },
            selectData: function (id) {
                if (this.selectedId.indexOf(id) != -1)
                    this.selectedId.splice(this.selectedId.indexOf(id), 1)
                else
                    this.selectedId.push(id)
            },
            getData: function () {
                let uri = process.env.VUE_APP_APIHOST + 'teacher/quiz/result/list';
    
                this.dataList = []
                this.dataFetch = true;
                this.$http.get(uri, {params: {
                    lessons_id: this.$route.params.id,
                    courses_id: this.$route.params.courses_id
                }}).then(res => {
                    this.dataFetch = false;
                    if (res.data.status)
                        this.dataList = res.data.data;
                });
            },
            deleteData: function (data) {
                this.$swal({
                    icon: 'info',
                    title: 'Apa apa kamu yakin ?',
                    text: 'Kamu akan menghapus data dengan nama ' + data.name,
                    showCancelButton: true,
                    confirmButtonText: 'Hapus',
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/quiz/result/reset', {
                            answer_id: data.answer_id,
                            })
                            .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.getData();
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                    }
                });
            },
            deleteBatch: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Apa apa kamu yakin ?',
                    text: 'Kamu akan menghapus ' + this.selectedId.length + ' data yang terpilih',
                    showCancelButton: true,
                    confirmButtonText: 'Hapus',
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/delete-member-batch', {
                                list_id: this.selectedId,
                            })
                            .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.selectedId=[];
                                            this.getData();
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                    }
                });
            },
            detail(data) {
                this.resultDetail={student: {}, list: []}
                this.resultDetail.student = data
                let uri = process.env.VUE_APP_APIHOST + 'teacher/quiz/result/detail';
    
                this.dataFetch = true;
                this.$http.get(uri, {params: {
                    answer_id: data.answer_id,
                }}).then(res => {
                    this.dataFetch = false;
                    if (res.data.status)
                        this.resultDetail.list = res.data.data;
                });
            },
            saveScore(data) {
                if (parseInt(data.score) < 0) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Nilai tidak bisa kurang dari 0',
                    });
                    data.score = 0
                } if (parseInt(data.score) > 0 && parseInt(data.score) > data.point) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Nilai tidak bisa melebihi point soal',
                    });
                    data.score = data.point
                } else {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/quiz/save/score', {
                        answer_detail_id: data.answer_detail_id,
                        status: data.status,
                        score: data.score,
                        type: data.type,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    // this.$refs.closeModal.click()
                                    this.detail(this.resultDetail.student);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            },
            filterItems: function (data) {
                var app = this;
                if (data.length > 0) {
                    return data.filter(function (result) {
                        let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                        return result.identity_number.toString().match(regex) || result.name.match(regex);
                    })
                } else {
                    return []
                }
            }
        },
    }
    </script>