<template>
<div>
    <div class="account-pages my-5 pt-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-8">
                    <div class="alert alert-info" style="text-align: justify">
                        Pembelajaran Bermakna (Meaningful Learning)
                        yaitu proses pembelajaran yang dilakukan
                        dengan cara menghubungkan informasi baru
                        dengan pengetahuan yang telah kamu miliki,
                        serta mengkaitkannya dengan pelajaran
                        sehingga akan menghasilkan konsep-konsep
                        baru.
                    </div>
                    <div class="card">
                        <div class="card-body p-4">
                            <div class="">
                                <div class="text-center">
                                    <a href="index.html" class="">
                                        <img src="assets/images/logo.png" alt="" height="100" class="auth-logo mx-auto">
                                    </a>
                                </div>
                                <!-- end row -->
                                <p class="text-center mx-3 text-muted mt-3 mb-5">Silahkan masukan username dan password kamu untuk melanjutkan ke aplikasi.</p>
                                <form class="form-horizontal" v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="mb-4">
                                                <label class="form-label" for="username">Username</label>
                                                <input type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.username.$error }" v-model="username" id="username" placeholder="Masukan username ...">
                                                <div v-if="submitted && !$v.username.required" class="invalid-feedback">Username wajib diisi!</div>
                                            </div>
                                            <div class="mb-4">
                                                <label class="form-label" for="password">Password</label>
                                                <input type="password" class="form-control" :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password" id="password" placeholder="Masukan password ...">
                                                <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password wajib diisi!</div>
                                            </div>

                                            <div class="row">
                                                <div class="col">
                                                    <div class="mt-3 mt-md-0">
                                                        <a href="auth-recoverpw.html" class="text-muted"><i class="mdi mdi-lock"></i> Lupa password?</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-grid mt-4">
                                                <button class="btn btn-primary waves-effect waves-light" type="submit">Log In</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div>
    </div>
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'Signin',
    metaInfo: {
        title: 'Signin',
        titleTemplate: '%s - Elearning'
    },
    data() {
        return {
            submitted: false,
            username: '',
            password: '',
        }
    },
    validations: {
        username: {
            required
        },
        password: {
            required
        }
    },
    methods: {
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Harap lengkapi form',
                });

                this.waiting = false;
                return;
            } else {
                this.$http.post(process.env.VUE_APP_APIHOST + 'auth/signin', {
                        username: this.username,
                        password: this.password
                    })
                    .then(response => {
                        this.waiting = false;

                        if (response.data.status) {
                            const token = response.data.user.key;
                            localStorage.setItem('lms_token', token);
                            location.href='';
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
            }
        }
    }
}
</script>
