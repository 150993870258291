<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0">Kelola Data Kelas Online</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                                </li>
                                <li class="breadcrumb-item active">Kelola Kelas Online</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div class="card-title mb-4 text-muted">Filter</div>
                                <div>
                                    <router-link :to="{ name: 'add_courses' }" class="btn btn-primary"><i
                                            class="mdi mdi-plus me-2"></i>Tambah Data Baru</router-link>
                                    <button type="button" class="btn ms-2" @click="deleteBatch()"
                                        :class="{ 'btn-secondary': selectedId.length == 0, 'btn-danger': selectedId.length > 0 }"
                                        :disabled="selectedId.length == 0"><i class="mdi mdi-delete me-2"></i>Hapus
                                        Terpilih</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 mb-2">
                                    <label>Tahun Periode</label>
                                    <select class="form-select border-muted" v-model="params.year_id" @change="getData()">
                                        <option value="">Semua Tahun Periode</option>
                                        <option v-for="row in periodeList" :value="row.id">{{ row.name }} {{ row.active ==
                                            '1' ? '(Aktif)' : ''
                                        }}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <label>Mata Pelajaran</label>
                                    <select class="form-select border-muted" v-model="params.course_category_id"
                                        @change="getData()">
                                        <option value="">Semua Mata Pelajaran</option>
                                        <option v-for="row in categoryList" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-5 mb-2">
                                    <label>Jurusan</label>
                                    <select class="form-select border-muted" v-model="params.program_study_id"
                                        @change="getData()">
                                        <option value="">Semua Jurusan</option>
                                        <option v-for="row in studyProgramList" :value="row.id">{{ row.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-12 mb-2">
                                    <label>Kata Kunci</label>
                                    <input type="text" class="form-control border-muted"
                                        placeholder="Cari siswa berdasarkan nis atau nama disini ..." v-model="searchQuery">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-4 text-muted">Dibawah ini terdapat {{ filterItems(dataList).length }}
                                mata pelajaran yang terdaftar didalam sistem.</h4>
                            <div class="table-responsive" v-if="filterItems(dataList).length > 0">
                                <table class="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input"
                                                        style="transform: scale(1.5);" id="customCheckall"
                                                        v-model="checkAll" @change="selectAllData()">
                                                    <label class="form-check-label" for="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th>Nama</th>
                                            <th>Mata Pelajaran</th>
                                            <th>Jurusan</th>
                                            <th>Kurikulum</th>
                                            <th>Guru</th>
                                            <th>Status</th>
                                            <th>Peserta</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in filterItems(dataList)" :key="row.id">
                                            <td>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="customCheck1"
                                                        style="transform: scale(1.5);" @change="selectData(row.id)"
                                                        :checked="selectedId.indexOf(row.id) != -1">
                                                    <label class="form-check-label" for="customCheck1"></label>
                                                </div>
                                            </td>
                                            <td>{{ row.name }}</td>
                                            <td>{{ row.category }}</td>
                                            <td>{{ row.program_study }}</td>
                                            <td>{{ row.curriculum }}</td>
                                            <td>
                                                <div v-if="row.teacher">{{ row.teacher }}</div>
                                                <div v-if="!row.teacher" class="text-muted">-</div>
                                            </td>
                                            <td>
                                                <label class="badge bg-primary"
                                                    v-if="row.status == 'publish'">Publish</label>
                                                <label class="badge bg-secondary"
                                                    v-if="row.status != 'publish'">Unpublish</label>
                                            </td>
                                            <td>
                                                <label v-if="row.participant">{{ row.participant }} Orang</label>
                                                <label v-if="!row.participant">Tidak ada</label>
                                            </td>
                                            <td width="15%">
                                                <button type="button" class="btn btn-info btn-sm me-2"
                                                    @click="member(row)">Lihat Peserta</button>
                                                <router-link :to="{ name: 'edit_courses', params: { id: row.id } }"
                                                    class="btn btn-outline-success btn-sm me-2">Edit</router-link>
                                                <button type="button" class="btn btn-outline-danger btn-sm"
                                                    @click="deleteData(row)">Hapus</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-block bg-light rounded p-3 text-center" v-if="filterItems(dataList).length == 0">
                                Data tidak ditemukan.</div>
                        </div>
                        <!-- end card-body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->

        </div>
        <modal name="list-participant" :adaptive="true" :height="'auto'">
            <div class="d-block p-3">
                <div class="d-block mb-3">
                    <div class="card-title m-3">
                        Daftar Peserta
                    </div>
                    <div class="d-block mt-3" v-if="memberList.length > 0">
                        <div class="d-flex justify-content-between align-items-center bg-light rounded p-3 m-3"
                            v-for="row in memberList">
                            <div class="d-block">
                                <div>{{ row.identity_number }}</div>
                                <label>{{ row.name }}</label>
                            </div>
                            <label>{{ row.study_group }}</label>
                        </div>
                    </div>
                    <div class="d-block mx-3 bg-light p-3 text-center text-muted" v-else>
                        Peserta tidak ada
                    </div>
                </div>
                <div class="d-flex justify-content-end m-3">
                    <button class="btn btn-light" @click="closeModal()">Tutup</button>
                </div>
            </div>
        </modal>
        <!-- container-fluid -->
    </div>
</template>

<script>
export default {
    name: 'CoursesCategory',
    metaInfo: {
        title: "CoursesCategory",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            selectedId: [],
            checkAll: false,
            dataFetch: false,
            searchQuery: '',
            params: {
                program_study_id: '',
                year_id: '',
                course_category_id: ''
            },
            dataList: [],
            categoryList: [],
            studyProgramList: [],
            periodeList: [],
            memberList: [],
        }
    },
    component: {},
    created() {
        this.getData();
        this.getStudyProgram();
        this.getYear();
        this.getCategory();
    },
    methods: {
        selectAllData: function () {
            if (this.checkAll) {
                this.dataList.forEach(element => {
                    this.selectData(element.id)
                });
            } else {
                this.selectedId = []
            }
        },
        selectData: function (id) {
            if (this.selectedId.indexOf(id) != -1)
                this.selectedId.splice(this.selectedId.indexOf(id), 1)
            else
                this.selectedId.push(id)
        },
        member: function (data) {
            this.memberList = []
            this.getMember(data);
            this.$modal.show('list-participant')
        },
        closeModal: function () {
            this.$modal.hide('list-participant')
        },
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'administrator/courses/list';

            this.dataList = [];
            this.dataFetch = true;
            this.$http.get(uri, { params: this.params }).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
            });
        },
        deleteData: function (data) {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus data dengan nama ' + data.name,
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/courses/delete', {
                        id: data.id,
                    })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteBatch: function () {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus ' + this.selectedId.length + ' data yang terpilih',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/courses/delete-batch', {
                        list_id: this.selectedId,
                    })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.selectedId = [];
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        getStudyProgram: function () {
            let uri = process.env.VUE_APP_APIHOST + 'program-study/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.studyProgramList = res.data.data;
                }
            });
        },
        getYear: function () {
            let uri = process.env.VUE_APP_APIHOST + 'year/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.periodeList = res.data.data;
                }
            });
        },
        getCategory: function () {
            let uri = process.env.VUE_APP_APIHOST + 'category/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.categoryList = res.data.data;
                }
            });
        },
        getMember: function (data) {
            let uri = process.env.VUE_APP_APIHOST + 'administrator/courses/member';

            this.$http.get(uri, {
                params: {
                    courses_id: data.id
                }
            }).then(res => {
                if (res.data.status) {
                    this.memberList = res.data.data;
                }
            });
        },
        filterItems: function (data) {
            var app = this;
            if (data) {
                return data.filter(function (result) {
                    let regex = new RegExp('(' + app.searchQuery + ')', 'i');
                    return result.name.match(regex) || result.name.match(regex);
                })
            } else {
                return [];
            }
        }
    },
}
</script>
