<template>
    <div class="row">
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="card-description mb-3">Silahkan lengkapi form dibawah ini.</div>
                    <form class="mt-3" v-on:submit.prevent="saveQuestion">
                        <div class="form-group mb-3">
                            <label class="label mb-1">Bobot Nilai</label>
                            <input type="number" class="form-control border-muted" :class="{ 'is-invalid': submitted && $v.form.point.$error }" placeholder="Masukan bobot nilai" v-model="form.point">
                            <div v-if="submitted && !$v.form.point.required" class="invalid-feedback">Bobot Nilai wajib diisi!</div>
                        </div>
                        <div class="form-group row mb-3">
                            <div class="col-md-6">
                                <label class="label mb-1">Tipe</label>
                                <select class="form-select border-muted" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                                    <option value="">Pilih Tipe</option>
                                    <option value="ESSAY">Essay</option>
                                    <option value="MULTIPLE_CHOICE">Pilihan Ganda</option>
                                </select>
                                <div v-if="submitted && !$v.form.point.required" class="invalid-feedback">Tipe wajib diisi!</div>
                            </div>
                            <div class="col-md-6">
                                <label class="label mb-1">Level of Cognitive</label>
                                <multiselect v-model="form.level" :multiple="true" :class="{ 'is-invalid': submitted && $v.form.level.$error }" :options="levelList" placeholder="Pilih Level of Cognitive" :show-labels="false" label="label" track-by="label">
                                    <template slot="option" slot-scope="props"><strong>{{ props.option.label }}</strong></template>
                                </multiselect>
                                <div v-if="submitted && !$v.form.level.required" class="invalid-feedback">Level of Cognitive wajib diisi!</div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label class="label">Pertanyaan</label>
                            <ckeditor :config="editorConfig" v-model="form.question" class="mt-2" :class="{ 'is-invalid': submitted && $v.form.question.$error }"></ckeditor>
                            <div v-if="submitted && !$v.form.question.required" class="invalid-feedback">Pertanyaan wajib diisi!</div>
                        </div>
                        <!-- IF TYPE IS MULTIPLE CHOICE -->
                        <div class="d-block rounded bg-light p-3 mb-3" v-if="form.type == 'MULTIPLE_CHOICE'">
                            <div class="d-flex justify-content-between mb-3 align-items-center">
                                <div class="d-flex align-items-center"><i class="mdi mdi-information me-2 font-size-20"></i><label class="label mb-0">Informasi Opsi Jawaban</label></div>
                                <button class="btn btn-link text-muted" type="button" @click="toggleOption()">{{hideOption.title}}</button>
                            </div>
                            <div class="d-block" v-if="hideOption.toggle == 'open'">
                                <div class="form-group mb-3">
                                    <label class="label mb-1">Jawaban Benar</label>
                                    <select class="form-select border-muted" v-model="form.answer">
                                        <option value="">Pilih Jawaban Benar</option>
                                        <option v-for="(row, index) in form.options" :value="alphabet[index]" :key="index">{{alphabet[index]}}</option>
                                    </select>
                                </div>
                                <div class="form-group mb-3" v-for="(row, index) in form.options" :key="index">
                                    <div class="d-flex justify-content-between">
                                        <label class="label">Pilihan {{alphabet[index]}}</label>
                                        <button class="btn btn-link text-muted" type="button" @click="removeOption(index)" v-if="index > 0"><i class="mdi mdi-delete me-1"></i>Hapus</button>
                                    </div>
                                    <ckeditor :config="editorConfigOption[index]" v-model="form.options[index]" class="mt-2"></ckeditor>
                                </div>
                                <div class="d-grid" v-if="form.options.length < 5">
                                    <button type="button" class="btn btn-info" @click="addOption()"><i class="mdi mdi-plus me-2"></i>Tambah Opsi Jawaban Baru</button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label class="label mb-1">Materi Terkait</label>
                            <multiselect v-model="form.related_lessons_id" :multiple="false" :options="listTheory" placeholder="Pilih Materi Terkait" :show-labels="false" label="label" track-by="label">
                                <template slot="option" slot-scope="props"><strong>{{ props.option.label }}</strong></template>
                            </multiselect>
                        </div>
                        <div class="form-group mb-3">
                            <label class="label">Capaian Pembelajaran</label>
                            <ckeditor :config="editorConfigAchievement" v-model="form.goals" class="mt-2"></ckeditor>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-light me-3" @click="deleteQuestion(form)">Hapus</button>
                            <button type="submit" class="btn btn-primary">Simpan</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <!-- Detail Information of Lesson -->
            <div class="card">
                <form class="mt-3" v-on:submit.prevent="updateLessons">
                    <div class="card-body">
                        <div class="d-flex h5 mb-3 align-items-center"><i class="ri ri-information-line font-size-20 me-2"></i>Informasi Pembelajaran</div>
                        <div class="d-block mb-3">
                            <div class="text-muted font-size-14 mb-2">Nama Pembelajaran</div>
                            <div class="text-dark font-weight-bold h5 py-2 px-3 bg-light rounded">{{lesson.name}}</div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="text-muted font-size-14 mb-2">Durasi Quiz</div>
                            <date-picker v-model="lesson.duration" class="w-100" valueType="format" type="time" :showSecond="false" format="hh:mm" placeholder="hh:mm"></date-picker>
                        </div>
                        <div class="form-group mb-3">
                            <div class="text-muted font-size-14 mb-2">Harus Kompeten ?</div>
                            <select class="form-select border-muted" v-model="lesson.kkm.is_competent">
                                <option value="">Pilih Wajib Kompeten</option>
                                <option value="1">Ya</option>
                                <option value="0">Tidak</option>
                            </select>
                        </div>
                        <div class="form-group mb-3">
                            <div class="text-muted font-size-14 mb-2">Nilai KKM</div>
                            <input type="number" class="form-control border-muted" placeholder="Masukan nilai KKM" v-model="lesson.kkm.kkm">
                        </div>
                        <div class="form-group mb-3">
                            <div class="text-muted font-size-14 mb-2">Maksimal Remedial</div>
                            <input type="number" class="form-control border-muted" placeholder="Masukan jumlah maksimal remedial" v-model="lesson.kkm.max_remedial">
                            <div class="badge badge-soft-info font-size-12 mt-2">Kosongkan jumlah remedial jika tidak dibatasi</div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="text-muted font-size-14 mb-2">Acak Soal</div>
                            <select class="form-select border-muted" v-model="lesson.is_random_question">
                                <option value="">Pilih Acak Soal</option>
                                <option value="1">Ya</option>
                                <option value="0">Tidak</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-end">
                            <router-link type="button" class="btn btn-secondary" :to="{name: 'edit_lessons', params: {courses_id: coursesId, section_id: lesson.sections_id, id: lesson.id}}">Detail Pembelajaran</router-link>
                            <button type="submit" class="btn btn-primary ms-2">Simpan Pembelajaran</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="d-flex h5 mb-3 align-items-center"><i class="ri ri-dashboard-line font-size-20 me-2"></i>Daftar Soal</div>
                    <div class="d-block bg-light p-3 rounded text-center">
                        <div v-if="question.length > 0">
                            <button type="button" class="btn btn-square" style="margin: 2px" v-for="(row, index) in question" @click="detailQuestion((index + 1), row)" :class="{'btn-primary': idQuestActive == row.id, 'btn-white': idQuestActive != row.id}">{{index + 1}}</button>
                        </div>
                        <div class="d-flex text-muted justify-content-center" v-else><i class="ri ri-information-line me-2"></i>Belum ada soal pertanyaan</div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-secondary" @click="resetQuestion()">Reset Soal</button>
                        <button type="button" class="btn btn-primary ms-2" @click="resetFormQuestion()">Tambah Soal</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    required
} from "vuelidate/lib/validators";
export default {
    name: 'Question',
    metaInfo: {
        title: "Kelola Soal",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            coursesId: this.$route.params.courses_id,
            alphabet: ['A', 'B', 'C', 'D', 'E', 'F'],
            question: [],
            dataFetch: false,
            idQuestActive: null,
            form: {
                lessons_id: this.$route.params.id,
                point: '',
                question: '',
                goals: '',
                type: '',
                level: '',
                answer: '',
                related_lessons_id: '',
                options: ['']
            },
            lesson:{
                duration: '',
                is_random_question: '',
                kkm: {
                    lessons_id: this.$route.params.id,
                    is_competent: '',
                    kkm: '',
                    max_remedial: ''
                }
            },
            listTheory: [],
            toolbar: [{
                name: 'basicstyles',
                items: ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                    'SpecialChar', 'EqnEditor', 'PasteFromWord'
                ]
            }],
            hideOption: {
                title: 'Tutup',
                toggle: 'open'
            },
            editorConfig: {versionCheck: false,toolbar: []},
            editorConfigAchievement: {versionCheck: false,toolbar: []},
            editorConfigOption: [],
            levelList: [
                {
                    value: 'c1',
                    label: 'C1 (Mengingat)'
                },
                {
                    value: 'c2',
                    label: 'C2 (Memahami)'
                },
                {
                    value: 'c3',
                    label: 'C3 (Melakukan)'
                },
                {
                    value: 'c4',
                    label: 'C4 (Menganalisa)'
                },
                {
                    value: 'c5',
                    label: 'C5 (Mengevaluasi)'
                },
                {
                    value: 'c6',
                    label: 'C6 (Mengkreasi)'
                },
            ],
            submitted: false
        }
    },
    mounted() {
        this.initTextEditor()
        this.getDataQuestion()
        this.getDataLesson()
        this.getTheory()
    },
    validations: {
        form: {
            point: {
                required
            },
            type: {
                required
            },
            level: {
                required
            },
            question: {
                required
            },
        },
    },
    methods: {
        getDataQuestion: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/quiz/question/list';

            this.question = []
            this.dataFetch = true;
            this.$http.get(uri, {params: {lessons_id: this.$route.params.id}}).then(res => {
                this.dataFetch = false;
                if (res.data.status)
                    this.question = res.data.data;
            });
        },
        getDataLesson: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/quiz/detail/lessons';

            this.$http.get(uri, { params: {id: this.$route.params.id} }).then(res => {
                if (res.data.status) {
                    this.lesson = res.data.data;
                }
            });
        },
        getTheory: function () {
            this.listTheory = []
            let uri = process.env.VUE_APP_APIHOST + 'teacher/lessons/list/theory';

            this.$http.get(uri, { params: {courses_id: this.$route.params.courses_id} }).then(res => {
                if (res.data.status) {
                    this.listTheory = res.data.data.map((r) => ({ value: r.id.toString(), label: r.name}));
                }
            });
        },
        updateLessons: function () {
            let formData = new FormData();

            formData.append('duration', this.lesson.duration)
            formData.append('is_random_question', this.lesson.is_random_question)
            for (var index in this.lesson.kkm) {
                formData.append(index, this.lesson.kkm[index]);
            }

            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/quiz/update/lessons', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.getDataLesson()
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
        },
        saveQuestion: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();
                // get list level id
                var listLevelId = [];
                for (var keyLevel in this.form.level) {
                    listLevelId.push(this.form.level[keyLevel].value)
                }
                formData.append('level', JSON.stringify(listLevelId))

                // get list related material id
                if (this.form.related_lessons_id)
                    formData.append('related_lessons_id', this.form.related_lessons_id.value)

                // json encode options
                formData.append('options', JSON.stringify(this.form.options))

                for (var index in this.form) {
                    if (['level', 'related_lessons_id', 'options'].indexOf(index) == -1)
                        formData.append(index, this.form[index])
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/quiz/question/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.resetFormQuestion()
                                    this.getDataQuestion()
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        detailQuestion(number, data) {
            data.number = number
            this.form = data
            this.idQuestActive = data.id
            this.initTextEditor()
        },
        deleteQuestion(data) {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus data soal pada nomor ' + data.number,
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/quiz/question/delete', {
                            id: data.id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getDataQuestion()
                                        this.resetFormQuestion()
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        resetQuestion() {
            this.$swal({
                icon: 'info',
                title: 'Apa apa kamu yakin ?',
                text: 'Kamu akan menghapus semua data soal',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/quiz/question/reset', {
                            lessons_id: this.$route.params.id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getDataQuestion()
                                        this.resetFormQuestion()
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        resetFormQuestion() {
            this.form = {
                lessons_id: this.$route.params.id,
                point: '',
                question: '',
                goals: '',
                type: '',
                level: '',
                answer: '',
                related_lessons_id: '',
                options: ['']
            }
            this.submitted = false
        },
        initTextEditor() {
            this.editorConfi = {versionCheck: false,toolbar: []}
            this.editorConfigAchievement = {versionCheck: false,toolbar: []}
            this.editorConfigOption = []

            this.editorConfig.toolbar = this.toolbar
            this.editorConfig.enterMode = 2
            this.editorConfigAchievement.toolbar = this.toolbar
            this.editorConfigAchievement.enterMode = 2

            if (this.form.type == 'MULTIPLE_CHOICE') {
                for (var i=0; i<this.form.options.length; i++) {
                    this.editorConfigOption.push({enterMode: 2, toolbar: this.toolbar})
                }
            }
        },
        addOption() {
            this.form.options.push('')
        },
        removeOption(index) {
            this.form.options.splice(index, 1)
        },
        toggleOption() {
            if (this.hideOption.toggle == 'open') {
                this.hideOption.toggle = 'hide'
                this.hideOption.title = 'Buka'
            } else {
                this.hideOption.toggle = 'open'
                this.hideOption.title = 'Tutup'
            }
        }
    },
}
</script>