<template>
<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Kelola Siswa</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'student' }">Siswa</router-link>
                            </li>
                            <li class="breadcrumb-item active">Form Siswa</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row justify-content-center">
            <div class="col-md-2 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="d-block mb-3 pb-2 text-center">
                            <div>
                                <img :src="preview_image" class="border-radius-1 w-100 border" />
                            </div>
                            <input type="file" @change="previewFiles" ref="file" accept="image/png, image/gif, image/jpeg" class="d-none" />
                            <div class="d-grid">
                                <button type="button" class="btn btn-outline-secondary btn-block mt-2" @click="$refs.file.click()">Choose Picture</button>
                            </div>
                        </div>
                        <div class="d-block mb-3 pb-2">
                            <ul class="nav nav-fill nav-pills flex-column">
                                <li class="nav-item mb-2">
                                    <a class="nav-link" href="javascript:void(0)" v-bind:class="{'active': currentTabComponent == 'profile', 'text-dark': currentTabComponent != 'profile', 'text-muted bg-light': !isFillComplete.profile && currentTabComponent != 'profile', 'text-dark bg-light': isFillComplete.profile && currentTabComponent != 'profile'}" v-on:click="changeTab('profile')"><i class="mdi mdi-account me-2"></i>Profile</a>
                                </li>
                                <li class="nav-item mb-2">
                                    <a class="nav-link" href="javascript:void(0)" v-bind:class="{'active': currentTabComponent == 'academic', 'text-dark': currentTabComponent != 'academic', 'text-muted bg-light': !isFillComplete.academic && currentTabComponent != 'academic', 'text-dark bg-light': isFillComplete.academic && currentTabComponent != 'academic'}" v-on:click="changeTab('academic')"><i class="mdi mdi-tag me-2"></i>Academic</a>
                                </li>
                                <li class="nav-item" v-if="!form.id">
                                    <a class="nav-link" href="javascript:void(0)" v-bind:class="{'active': currentTabComponent == 'password', 'text-dark': currentTabComponent != 'password', 'text-muted bg-light': !isFillComplete.password && currentTabComponent != 'password', 'text-dark bg-light': isFillComplete.password && currentTabComponent != 'password'}" v-on:click="changeTab('password')"><i class="mdi mdi-lock me-2"></i>Password</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 grid-margin stretch-card">
                <keep-alive>
                    <component :form="form" v-on:listenerChild="listenerChild" :is="currentTabComponent"></component>
                </keep-alive>
            </div>
        </div>
        <!-- end row -->

    </div>
    <!-- container-fluid -->
</div>
</template>

<script>
// import tab components
import profile from '@/views/administrator/student/tabs/Profile.vue';
import academic from '@/views/administrator/student/tabs/Academic.vue';
import password from '@/views/administrator/student/tabs/Password.vue';

export default {
    name: 'FormStudent',
    metaInfo: {
        title: "Form Siswa",
        titleTemplate: "%s - Elearning"
    },
    components: {
        profile,
        academic,
        password,
    },
    data() {
        return {
            id: this.$route.params.id,
            currentTabComponent: 'profile',
            form: {
                // Tab profile
                id: "",
                identity_number: "",
                name: "",
                phone: "",
                email: "",
                place_birth: "",
                date_birth: "",
                gender: "male",
                religion: "",
                address: "",

                // Tab academic
                study_program_id: "",
                education_id: "",
                curriculum_id: "",
                study_group_id: "",
                entry_date: "",
                type: "pindahan",
                study_limit: "",
                status_id: "",

                // Tab password
                password: "",
                confirm_password: "",
            },
            photo: '',
            isFillComplete: {
                profile: false,
                academic: false,
                password: false
            },
            preview_image: 'https://via.placeholder.com/513x342',
            submitted: false,
            waiting: false,
        }
    },
    created() {
        this.fetchDetail();
    },
    methods: {
        listenerChild(response) {
            for (var index in response) {
                this.form[index] = response[index];
            }

            if (!this.isFillComplete.profile || !this.isFillComplete.academic || !this.isFillComplete.password) {
                if (this.currentTabComponent == 'profile') {
                    this.isFillComplete.profile = true;
                    this.changeTab('academic');
                } else if (this.currentTabComponent == 'academic' && !this.form.id) {
                    this.isFillComplete.academic = true;
                    this.changeTab('password');
                } else {
                    this.saveData();
                }
            } else {
                this.saveData();
            }
        },
        saveData: function () {
            this.waiting = true;
            this.submitted = true;
            let formData = new FormData();

            for (var index in this.form) {
                formData.append(index, this.form[index]);
            }
            formData.append('username', this.form.identity_number);
            formData.append('photo', this.photo);

            this.$http.post(process.env.VUE_APP_APIHOST + 'administrator/student/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'student'
                                });
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
        },
        fetchDetail: function() {
            if (typeof (this.id) !== 'undefined' && this.id !== null) {
                this.label  = "Update";

                let uri     = process.env.VUE_APP_APIHOST+'administrator/student/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse     = res.data.data;
                        this.form           = getResponse;
                        if (this.form.picture)
                            this.preview_image  = this.form.picture;
                            
                        this.isFillComplete.profile = true
                        this.isFillComplete.academic = true
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'student' });
                            }
                        });
                    }
                });
            }
        },
        changeTab: function (tabActive) {
            var isEmpty = false
            var message = ""
            if (this.currentTabComponent != tabActive) {
                if (tabActive == 'academic' && !this.isFillComplete.profile) {
                    isEmpty = true
                    message = "Please complete the form tab profile and save the data first";
                } else if (tabActive == 'password') {
                    if (!this.isFillComplete.profile) {
                        isEmpty = true
                        message = "Please complete the form tab profile and save the data first";
                    } else if (!this.isFillComplete.academic) {
                        isEmpty = true
                        message = "Please complete the form tab academic and save the data first";
                    }
                } else if (this.currentTabComponent == 'password') {
                    if (!this.isFillComplete.password && !this.isFillComplete[tabActive]) {
                        isEmpty = true
                        message = "Please complete the form and save the data first";
                    }
                }
            }

            if (isEmpty) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: message,
                });
            } else {
                this.currentTabComponent = tabActive;
            }
        },
        previewFiles(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.preview_image = URL.createObjectURL(file);
                this.photo = file;
            }
        },
    },
}
</script>
