<template>
    <div class="row">
        <div class="col-md-12">
            <div id="accordion" v-if="dataList.length > 0">
                <div class="card" v-for="row in dataList" :key="row.id">
                    <div class="card-header bg-white p-3 border-bottom" id="headingOne">
                        <div class="row">
                            <div class="col-md-12">
                                <a data-bs-toggle="collapse" data-parent="#accordion" :href="'#collapseOne' + row.id" aria-expanded="true" aria-controls="collapseOne" class="text-dark">
                                    <h5 class="font-size-16 ms-2">{{row.section}}</h5>
                                    <h5 class="mb-0 ms-2 font-size-14 text-muted">{{row.child.length}} Materi</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div :id="'collapseOne' + row.id" class="collapse" aria-labelledby="headingOne" data-parent="#accordion" :style="{'min-height': row.child.length == 1 ? '200px' : ''}">
                        <div class="card-body">
                            <div class="mx-4 mb-3" v-if="row.child.length > 0">
                                <a href="javascript:void(0)" class="text-body d-block" v-for="(lesson, index) in row.child" :class="{'border-bottom': index < (row.child.length-1)}" @click="detailLesson(lesson)">
                                    <div class="d-flex py-3 align-items-center">
                                        <div class="flex-shrink-0 me-3 align-self-start">
                                            <div class="avatar-sm">
                                                <span class="avatar-title bg-soft-primary rounded-circle text-primary" v-if="!lesson.locked">
                                                    <i class="ri-list-check-2 font-size-20" v-if="lesson.type == 'QUIZ' || lesson.type == 'FINAL_EXAM'"></i>
                                                    <i class="ri-todo-line font-size-20" v-if="lesson.type == 'ASSIGNMENT'"></i>
                                                    <i class="ri-file-list-3-line font-size-20" v-if="lesson.type == 'RESUME'"></i>

                                                    <i class="ri-file-list-3-line font-size-20" v-if="lesson.type == 'LESSON' && lesson.format == 'TEXT'"></i>
                                                    <i class="ri-sticky-note-line font-size-20" v-if="lesson.type == 'LESSON' && lesson.format == 'FILE'"></i>
                                                    <i class="ri-music-line font-size-20" v-if="lesson.type == 'LESSON' && lesson.format == 'AUDIO'"></i>
                                                    <i class="ri-live-line font-size-20" v-if="lesson.type == 'LESSON' && (lesson.format == 'VIDEO' || lesson.format == 'YOUTUBE')"></i>
                                                    <i class="ri-links-line font-size-20" v-if="lesson.format == 'LINK'"></i>
                                                </span>
                                                <span class="avatar-title bg-soft-secondary rounded-circle text-secondary" v-else>
                                                    <i class="ri-lock-line font-size-20"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h5 class="font-size-14 mb-1">{{lesson.name}}</h5>
                                            <p class="text-truncate mb-0">
                                                <!-- Tanggal dibuat : Apr 22, 2022, 11:03:03 AM -->
                                                Tanggal dibuat : {{lesson.created_at | formatDate('MMM DD, YYYY hh:mm')}}
                                            </p>
                                            <div class="d-flex mt-2" v-if="lesson.is_expired == '1'">
                                                <div class="p-2 rounded bg-light w-25">
                                                    <div class="d-flex text-muted">
                                                        <i class="ri ri-calendar-line me-2"></i>
                                                        <div class="d-block">
                                                            <div>
                                                                Tanggal Mulai
                                                            </div>
                                                            <div class="font-size-15 text-dark font-weight-400">
                                                                {{lesson.start_date | formatDate('MMM DD, YYYY')}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-2 rounded bg-light w-25 ms-2">
                                                    <div class="d-flex text-muted">
                                                        <i class="ri ri-calendar-line me-2"></i>
                                                        <div class="d-block">
                                                            <div>
                                                                Tanggal Selesai
                                                            </div>
                                                            <div class="font-size-15 text-dark font-weight-400">
                                                                {{lesson.end_date | formatDate('MMM DD, YYYY')}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-shrink-0 font-size-16 me-3">
                                            <span class="badge bg-primary me-2" v-if="lesson.status == 'publish'">PUBLISH</span>
                                            <span class="badge bg-secondary me-2" v-if="lesson.status == 'unpublish'">UNPUBLISH</span>
                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'LESSON'">MATERI</span>
                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'ASSIGNMENT'">TUGAS</span>
                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'QUIZ'">QUIZ</span>
                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'FINAL_EXAM'">UJIAN AKHIR</span>
                                            <span class="badge badge-soft-primary mx-2" v-if="lesson.type == 'RESUME'">RANGKUMAN</span>
                                            <span class="badge bg-light ms-2 text-uppercase">{{lesson.format}}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="mx-4">
                                <div class="d-block p-3 bg-light text-center mb-3 rounded-3 text-muted" v-if="row.child.length == 0">Belum ada materi yang dibuat.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="dataList.length == 0" class="d-block p-3 text-muted text-center">
                Kamu belum memiliki pembelajaran saat ini.
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Lessons',
    metaInfo: {
        title: "Daftar Pembelajaran",
        titleTemplate: "%s - Elearning"
    },
    data() {
        return {
            dataFetch: false,
            dataList: [],
            params: {
                id: this.$route.params.id
            },
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'student/lessons/list';

            this.dataList = [];
            this.dataFetch = true;
            this.$http.get(uri, { params: this.params }).then(res => {
                this.dataFetch = false;
                if (res.data.status)
                    this.dataList = res.data.data;
            });
        },
        detailLesson(data) {
            if(!data.locked) {
                if (data.type == 'LESSON' || data.type == 'RESUME') {
                    this.$router.push({ name: 'detail_lessons_student', params: {courses_id: this.$route.params.id, id: data.id}});
                } else if (data.type == 'QUIZ' || data.type == 'FINAL_EXAM') {
                    this.$router.push({ name: 'quiz_overview', params: {courses_id: this.$route.params.id, id: data.id}});
                } else if (data.type == 'ASSIGNMENT') {
                    this.$router.push({ name: 'assignment', params: {courses_id: this.$route.params.id, id: data.id}});
                }
            } else {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Materi masih terkunci, silahkan buka materi sebelumnya terlebih dahulu!',
                });
            }
        }
    },
}
</script>